
import {
    Box,
    Stack,
    Typography,
    Table, TableBody,
    TableContainer, TableHead, TableRow,
    Pagination, styled,
    Paper,
    Skeleton,
    Divider,
    Select,
    MenuItem,
    TextField,
    IconButton,
    InputBase,
} from "@mui/material";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import React, { useContext, useEffect, useState } from "react";
import Container from "../components/container";
import Drawer from "../components/drawer";
import { useNavigate } from "react-router-dom";
import { Context as AppContext } from "../context/AppContext";
import SearchIcon from '@mui/icons-material/Search';
import moment from "moment";
import BreadcrumbTrail from "../components/BreadcrumbsTrail";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import {
    ArrowDownward,
    ArrowUpward,
    Clear,
    Sort as SortIcon,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "../store";
import { getCustomers } from "../slices/customerSlice";


const SearchBox = (
    { search, handleSearch, handleCancelSearch }
) => {
    const classes = tableStyles();
    return (
        <Paper
            className={classes.searchContainer}
        >
            <IconButton sx={{ p: "6px", pointerEvents: 'none' }} aria-label="menu">
                <SearchIcon />
            </IconButton>
            <InputBase
                sx={{ flex: 1, fontSize: 14 }}
                placeholder="Search customers"
                inputProps={{ 'aria-label': '' }}
                value={search}
                onChange={handleSearch}
            />
            <IconButton
                sx={{ p: "6px" }}
                onClick={handleCancelSearch}
            >
                <Clear />
            </IconButton>
        </Paper>
    )
}

const Customers = () => {
    const [containerClass, setContainerClass] = useState("");
    const [drawerContainerClass, setDrawerContainerClass] = useState("");
    const classes = tableStyles();
    const { state, show } = useContext(AppContext);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [loading, setLoading] = useState(true);
    const [contentLoading, setContentLoading] = useState(true)
    const [records, setRecords] = useState({
        data: [],
        count: 0
    })
    const [sortingMode, setSortingMode] = useState({
        name: "CreatedAt",
        mode: "desc"
    });
    const [search, setSearch] = useState("");
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
    const dispatch = useDispatch();
    const rdx = useSelector((state) => state.customers);

    const navigate = useNavigate();

    const handleSorting = (name) => {
        if (sortingMode.name === name && sortingMode.mode === "desc") {
            setSortingMode({ name: null, mode: null })
        }
        else if (sortingMode.name === name) {
            setSortingMode({ name: name, mode: sortingMode.mode === null ? "asc" : (sortingMode.mode === "desc" ? "asc" : "desc") })
        }
        else {
            setSortingMode({ name: name, mode: "asc" })
        }
    }

    useEffect(() => {
        if (show) {
            setContainerClass(null);
            setDrawerContainerClass(null);
        } else {
            setContainerClass(classes.containeropen);
            setDrawerContainerClass(classes.drawercontaineropen);
        }
    }, [show]);

    useEffect(() => {
        setRecords({
            data: rdx.customers,
            count: rdx.count
        })
    }, [rdx.customers, rdx.count])


    useEffect(() => {
        if (rdx.customers && rdx.customers.length == 0) {
            setContentLoading(rdx.loading);
            setLoading(rdx.loading);
        } else if (rdx.customers.length !== 0 && rdx.loading) {
            setContentLoading(rdx.loading);
        } else {
            setContentLoading(false);
            setLoading(false);
        }
    }, [rdx.loading])

    const getCustomersRdx = async () => {
        try {
            dispatch(getCustomers(state.token, page, perPage, debouncedSearchTerm, sortingMode));
        } catch (e) {
            console.log("Customer list API failed to get data", e)
        }
    }

    const init = async () => {
        getCustomersRdx()
    };

    useEffect(() => {
        const timerId = setTimeout(() => {
            setDebouncedSearchTerm(search);
        }, 500);

        return () => {
            clearTimeout(timerId);
        };
    }, [search]);

    useEffect(() => {
        init()
    }, [page, perPage, debouncedSearchTerm, sortingMode]);

    const handleNav = (item) => {
        if (item.id) {
            navigate(
                `/home/customers/profile/${item.id}`,
                { state: { userId: item.userId, name: `${item.firstName} ${item.secondName}` } }
            );
        }
    }

    const handleSearch = (e) => {
        if (page !== 1) {
            setPage(1)
        }
        setSearch(e.target.value)
    }

    const handleCancelSearch = () => {
        setPage(1);
        setSearch("");
    }


    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: "white",
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 16,
            fontWeight: 500
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(even)': {
            backgroundColor: "#eafafc",
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setPerPage(+event.target.value);
        setPage(1);
    };


    const Sorting = ({ name }) => {
        const SortingIcon = () => {
            if (sortingMode.name === name) {
                switch (sortingMode.mode) {
                    case "asc":
                        return <ArrowUpward color="inherit" />
                    case "desc":
                        return <ArrowDownward color="inherit" />
                    default:
                        return <SortIcon color="inherit" />
                }
            } else {
                return <SortIcon color="inherit" />
            }
        }
        return (
            <IconButton
                style={{ color: sortingMode.name === name ? "disabled" : "white" }}
                disabled={contentLoading}
                onClick={() => handleSorting(name)}
            >
                <SortingIcon />
            </IconButton>
        )
    }

    return (
        <>
            <Stack direction="row">
                <Box
                    height="100%"
                    className={clsx(classes.drawercontainer, drawerContainerClass)}
                >
                    <Drawer show={show} location="customers" />
                </Box>
                <Box className={clsx(classes.container, containerClass)}>
                    <BreadcrumbTrail sx={{ my: 2 }} />
                    <Container
                        loading={contentLoading}
                        style={{ root: { margin: 40, marginTop: 0 } }}
                        title="Customers"
                        sideComponent={
                            <SearchBox
                                disabled={contentLoading}
                                search={search}
                                handleSearch={handleSearch}
                                handleCancelSearch={handleCancelSearch}
                            />
                        }  >
                        <Box className={classes.customers}>
                            <Box className={classes.agentTable} style={{ width: "100%" }}>
                                <TableContainer component={Paper} className="tableContainer">
                                    <Table >
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell width={40} align="right">
                                                    <div className={classes.flexBox}  >
                                                        <div> &nbsp;&nbsp;ID</div>
                                                        <Sorting name={"Id"} />
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell align="left">
                                                    <div className={classes.flexBox}>
                                                        Name
                                                        <Sorting name={"FirstName"} />
                                                    </div>
                                                </StyledTableCell>
                                                {/* <StyledTableCell align="center">Address</StyledTableCell> */}
                                                <StyledTableCell align="left">
                                                    Email
                                                </StyledTableCell>
                                                <StyledTableCell align="center" >
                                                    <div className={classes.flexBox} style={{ width: "max-content" }}>
                                                        Created At
                                                        <Sorting name={"CreatedAt"} />
                                                    </div>
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    Phone Number
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    Status
                                                </StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className={classes.customerTableBody}>
                                            {loading ?
                                                <>
                                                    {Array.from({ length: perPage }, () => Math.floor(Math.random() * 40)).map((x, index) => {
                                                        return (
                                                            <StyledTableRow key={index}>
                                                                <StyledTableCell><Skeleton variant="rectangular" height={25} /></StyledTableCell>
                                                                <StyledTableCell><Skeleton variant="rectangular" height={25} /></StyledTableCell>
                                                                <StyledTableCell><Skeleton variant="rectangular" height={25} /></StyledTableCell>
                                                                <StyledTableCell><Skeleton variant="rectangular" height={25} /></StyledTableCell>
                                                                <StyledTableCell><Skeleton variant="rectangular" height={25} /></StyledTableCell>
                                                                <StyledTableCell><Skeleton variant="rectangular" height={25} /></StyledTableCell>
                                                                {/* <StyledTableCell><Skeleton variant="rectangular" height={25} /></StyledTableCell> */}
                                                                {/* <StyledTableCell><Skeleton variant="rectangular" height={25} /></StyledTableCell> */}
                                                            </StyledTableRow>
                                                        )
                                                    })}
                                                </>
                                                :
                                                records.data.length ?
                                                    records.data.map((item, index) => (
                                                        <StyledTableRow key={item.id}>
                                                            <StyledTableCell>
                                                                <Typography>&nbsp;&nbsp;{item.id}</Typography>
                                                            </StyledTableCell>
                                                            <StyledTableCell align="left">
                                                                <Typography>
                                                                    <a
                                                                        variant="text"
                                                                        onClick={() => handleNav(item)}
                                                                        className="clickable"
                                                                    >  {item.firstName} {item.secondName}
                                                                    </a>
                                                                </Typography>
                                                            </StyledTableCell>

                                                            {/* <StyledTableCell align="center" >
                                                                <Typography>{item.address ?? "-"}</Typography>
                                                            </StyledTableCell> */}

                                                            <StyledTableCell align="left" >
                                                                <Typography>{item.emailId ?? "-"}</Typography>
                                                            </StyledTableCell>

                                                            <StyledTableCell align="left" >
                                                                <Typography>
                                                                    {moment(item.createdAt).format(
                                                                        "MM/DD/YYYY hh:mm:ss"
                                                                    )}
                                                                </Typography>
                                                            </StyledTableCell>

                                                            <StyledTableCell align="center">
                                                                <Typography>{item.phone ?? "-"}</Typography>
                                                            </StyledTableCell>

                                                            <StyledTableCell align="center">
                                                                <Box
                                                                    className={classes.status}
                                                                    style={{
                                                                        backgroundColor:
                                                                            (item.isVerified && item.isTNCAgreed) === true ? "#4dbd74" : "#f8cb00"
                                                                    }}

                                                                >
                                                                    <Typography color="#fff" fontWeight={"bold"} fontSize={10}>
                                                                        {(item.isVerified && item.isTNCAgreed) === true ? "Active" : "Pending"}
                                                                    </Typography>
                                                                </Box>
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    ))
                                                    : (
                                                        <StyledTableRow>
                                                            {["", "No records", "", "", "", "", "", "", ""].map(content => <StyledTableCell >{content}</StyledTableCell>)}
                                                        </StyledTableRow>
                                                    )
                                            }
                                        </TableBody>
                                    </Table>
                                    <Divider />
                                    <Box
                                        className={classes.pagination}>
                                        <Typography className={classes.showingCount}>Showing Customers ({records.data?.length}/{records.count})</Typography>
                                        <Box className={classes.flexBox}>
                                            <Pagination
                                                count={Math.ceil(records.count / perPage)}
                                                className={classes.pageButtons}
                                                color="primary"
                                                variant="outlined"
                                                shape="rounded"
                                                page={page}
                                                disabled={contentLoading}
                                                onChange={handleChangePage}
                                            />

                                            <Box className={classes.flexBox}>
                                                <Typography fontSize={14} >Row per page: </Typography>
                                                <Select sx={{ fontSize: 14 }} size="small" value={perPage} disabled={loading || contentLoading} onChange={handleChangeRowsPerPage}>
                                                    {[5, 10, 25, 50].map((val) => (
                                                        <MenuItem sx={{ fontSize: 14 }} value={val}>{val}</MenuItem>
                                                    ))}
                                                </Select>
                                            </Box>
                                        </Box>
                                    </Box>

                                </TableContainer>
                            </Box>
                        </Box>
                    </Container>
                </Box >
            </Stack >
        </>
    );
};

export default Customers;

export const tableStyles = makeStyles(theme => ({
    container: {
        width: "80%",
        paddingTop: 60,
        transition: "width 0.3s ease-out",
        // zIndex: -1,
    },
    containeropen: {
        width: "100%",
    },
    drawercontainer: {
        width: "20%",
        transition: "width 0.1s ease-out",
    },
    drawercontaineropen: {
        width: "0%",
        zIndex: 100,
    },
    status: {
        display: "flex",
        justifyContent: "center",
        padding: "2px 5px"
    },
    searchContainer: {
        padding: '1px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 300,
        border: "1px solid #6B7280"
    },
    customers: {
    },
    agentTable: {
        "& .MuiTableContainer-root": {
            // borderRadius: "0px !important",
        },
        "& .MuiTableCell-head": {
            // display: "flex",
            // gap: 5,
            fontWeight: "normal",
            backgroundColor: `${theme.palette.primary.main} !important`,
            color: "white",
            fontSize: 14,
            padding: "10px 5px"
        },
        "& .clickable": {
            textDecoration: "none",
            textTransform: "uppercase",
            padding: 0,
            fontSize: 14,
            color: theme.palette.primary.main,
            cursor: "pointer"
        },
        "& .MuiTypography-root": {
            fontSize: 14,
        },
        "& .tableContainer": {
            // border: "1px solid #FF7676",
            // borderRadius: 20
        },
        "& .planProvider": {
            fontWeight: 700
        },
        "& .cellContainer": {
            width: "100%",
            display: "flex",
            justifyContent: "center"
        },
    },
    customerTableBody: {
        "& .MuiTableCell-body": {
            fontSize: 14
        },
        "& .MuiTableCell-root": {
            padding: "12px 7px"
        }
    },
    flexBox: {
        display: "flex",
        alignItems: "center",
        gap: 5,
        height: "100%"
    },
    pagination: {
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        gap: 5,
        height: "100%",
        marginBottom: 4,
        padding: "0px 10px",
        gap: "20px !important",
        justifyContent: "space-between",
        "&.MuiSelect-select": {
            padding: "5px 14px !important"
        },
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            justifyContent: "normal",
            alignItems: "flex-start",
            gap: "10px !important"
        }
    },
    showingCount: {
        fontWeight: 'bold',
        fontSize: "14px !important"
    },
    pageButtons: {
        margin: "8px 0px",
        fontSize: 12,
        [theme.breakpoints.down("md")]: {
            margin: "2px !important"
        }
    }
}));
