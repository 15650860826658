import { Box, Card, Stack, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles";
import moment from "moment"

export default ({
    status,
    brand,
    last4Digits,
    name,
    expiryMonth,
    expiryYear,
    ...props
}) => {
    const formattedNumber = `**** **** **** ${last4Digits ?? "****"}`
    const classes = useStyles()

    return (
        <Card {...props} className={classes.root} sx={{ borderRadius: 3 }} >
            <Stack direction={"column"} justifyContent={"space-evenly"} height={"100%"} >
                <Stack direction={"row"} justifyContent={"space-between"} >
                    <img
                        src="/chip.png"
                        width={25}
                        style={{ objectFit: "contain", marginLeft: 20 }}
                    />
                    {brand && <img src={`https://logo.clearbit.com/${brand}.com`}
                        width={60}
                        style={{ objectFit: "contain" }}
                    />}
                </Stack>
                <Typography align="left" fontSize={16} variant="h6" letterSpacing={3} fontWeight={600}>{formattedNumber}</Typography>
                <Stack direction={"row"} justifyContent={"space-between"}>
                    <Typography fontSize={14} fontWeight={"600"}>{name}</Typography>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-end",
                        gap: 3
                    }}>
                        <Typography fontSize={12} color="GrayText">Valid thru</Typography>
                        <div>
                            <Typography fontSize={12} color="GrayText">MM/YY</Typography>
                            <Typography fontSize={12} fontWeight={"600"}>
                                {expiryMonth ? String(expiryMonth).padStart(2, "0") : "**"}
                                /
                                {expiryYear ?? "****"}
                            </Typography>
                        </div>
                    </Box>
                </Stack>
            </Stack>
        </Card>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        padding: 30,
        height: 200,
        width: 350,
        backgroundColor: "#F9F7F6 !important",
        boxShadow: "5px 5px 5px #aaaaaa !important",
        border: "1px solid #aaaa"
    }
}))