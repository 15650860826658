import React, { useContext, useEffect, useState } from "react";
import { Context as AppContext } from "../context/AppContext";
import { SlUser } from "react-icons/sl";
import { CiLock } from "react-icons/ci";

import {
    Box,
    Typography,
    Stack,
    TextField,
    InputBase,
    Button,
    Paper,
    Divider,
    IconButton,
    CircularProgress,
    FormHelperText,
} from "@mui/material";
import { MuiOtpInput } from 'mui-one-time-password-input'
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { CSHeader } from "../components/header";
import { BsEyeFill, BsEyeSlash } from "react-icons/bs";

export const ForgotPassEmailPage = (props) => {
    const [email, setEmail] = useState("");
    const [btnLoading, setBtnLoading] = useState(false);
    const classes = useStyles();
    const navigate = useNavigate();

    const { state, signin } = useContext(AppContext);

    const submit = async (e) => {
        e.preventDefault()
        setBtnLoading(true)

        setTimeout(() => {
            navigate("/forgot-password/verify", { state: { email: email } })
        }, 200)
    };

    return (
        <Box className={classes.root}>
            <CSHeader />
            <Box className={clsx(classes.center, classes.card)} >
                <Paper
                    sx={{ borderRadius: 8 }}
                    className={classes.main}
                >
                    <img className={classes.img}
                        // src="https://images.unsplash.com/photo-1550859492-d5da9d8e45f3?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
                        // src="https://plus.unsplash.com/premium_photo-1671482215345-3c7001c4e31f?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        src="https://images.unsplash.com/photo-1548337138-e87d889cc369?q=80&w=2096&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />

                    <Box className={classes.container}>
                        <Typography variant="h4" mb={2}>Forgot password</Typography>
                        <Typography
                            fontSize="15"
                            mt={1}
                            color="#a5a5a5"
                            fontWeight="small"
                        >
                            Enter your username
                        </Typography>
                        <form onSubmit={submit}>
                            <Stack direction="column" mt={2} gap={2}>
                                <Paper className={classes.paperBase}>
                                    <Box className={classes.inpIcon}>
                                        <SlUser size={16} color="#9fa1a3" />
                                    </Box>
                                    <InputBase
                                        autoFocus
                                        type="email"
                                        className={classes.input}
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </Paper>

                                <Button
                                    type='submit'
                                    disabled={btnLoading || !email}
                                    variant="contained"
                                >
                                    {btnLoading ?
                                        <Stack direction={"row"}>
                                            <Typography>please wait...</Typography>
                                        </Stack>
                                        : <span style={{ fontWeight: "bold", color: 'white' }}>
                                            Send OTP
                                        </span>}
                                </Button>

                                <Button
                                    variant="text"
                                    sx={{ textTransform: "none" }}
                                    onClick={() => navigate("/")}
                                >
                                    Back to login
                                </Button>
                            </Stack>
                        </form>
                    </Box>
                </Paper>
            </Box >
        </Box >
    );
};

export const ForgotPassOTPPage = () => {
    const location = useLocation();
    if (!location?.state?.email) {
        window.location.href = "/forgot-password/email"
        return null
    }
    const navigate = useNavigate();
    const [view, setView] = useState("verify")
    const [otp, setOtp] = React.useState('')
    const [pass1, setPass1] = useState('')
    const [showPass1, setShowPass1] = useState(false)
    const [pass2, setPass2] = useState('')
    const [showPass2, setShowPass2] = useState(false)
    const [error, setError] = useState(null)
    const [btnLoading, setBtnLoading] = useState(false);
    const classes = useStyles();

    const handleChange = (newValue) => {
        setOtp(newValue)
    }

    const checkOTP = async (e) => {
        e.preventDefault()
        setBtnLoading(true)
        setTimeout(() => {
            setView("setPass")
            setBtnLoading(false)
        }, 300)
    };

    const changePass = async (e) => {
        e.preventDefault()
        if (pass1 !== pass2) {
            setError("Password does not match")
            return
        }
        setError(null)
        setBtnLoading(true)
        setTimeout(() => {
            setView("success")
            setBtnLoading(false)
        }, 300)
    };

    return (
        <Box className={classes.root}>
            <CSHeader />
            <Box className={clsx(classes.center, classes.card)} >
                <Paper
                    sx={{ borderRadius: 8 }}
                    className={classes.main}
                >
                    <img className={classes.img}
                        src="https://images.unsplash.com/photo-1548337138-e87d889cc369?q=80&w=2096&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    />

                    {view === "verify" ? (
                        <Box className={classes.container}>
                            <Typography variant="h4" mb={2}>Verify OTP</Typography>
                            <Typography
                                fontSize="15"
                                mt={1}
                                color="#a5a5a5"
                                fontWeight="small"
                            >
                                An email with OTP has been sent to <b>{location.state.email}</b>, be sure to check your and spam folder.
                                <br />
                                <br />
                                Enter OTP here
                            </Typography>

                            <form onSubmit={checkOTP}>
                                <Stack direction="column" mt={2} gap={2}>

                                    <MuiOtpInput
                                        autoFocus={true}
                                        value={otp}
                                        length={6}
                                        TextFieldsProps={{ type: "number" }}
                                        onChange={handleChange}
                                    />

                                    <Button
                                        disabled={btnLoading || otp.length < 6}
                                        type="submit"
                                        variant="contained"
                                    >
                                        {btnLoading ?
                                            <Stack direction={"row"}>
                                                <Typography>please wait ...</Typography>
                                            </Stack>
                                            : <span style={{ fontWeight: "bold", color: 'white' }}>
                                                Verify
                                            </span>}
                                    </Button>

                                    <Button
                                        variant="text"
                                        sx={{ textTransform: "none" }}
                                        onClick={() => navigate("/")}
                                    >
                                        Back to login
                                    </Button>
                                </Stack>
                            </form>
                        </Box>)
                        : view === "setPass" ? (
                            <Box className={classes.container}>
                                <Typography variant="h4" mb={2}>Change password</Typography>
                                <Typography
                                    fontSize="15"
                                    mt={1}
                                    color="#a5a5a5"
                                    fontWeight="small"
                                >
                                    Set a new password
                                </Typography>

                                <form onSubmit={changePass}>
                                    <Stack direction="column" mt={2} gap={2}>
                                        <div>
                                            <Typography fontSize={14} >New password</Typography>
                                            <Paper className={classes.paperBase}>
                                                <Box className={classes.inpIcon}>
                                                    <CiLock size={20} color="#9fa1a3" />
                                                </Box>
                                                <InputBase
                                                    autoFocus
                                                    className={classes.input}
                                                    value={pass1}
                                                    type={showPass1 ? "text" : "password"}
                                                    onChange={(e) => setPass1(e.target.value?.trim())}
                                                />
                                                <Box className={classes.eye}>
                                                    <IconButton onClick={() => setShowPass1(!showPass1)}>
                                                        {showPass1 ? <BsEyeSlash size={18} /> : <BsEyeFill size={18} />}
                                                    </IconButton>
                                                </Box>
                                            </Paper>
                                        </div>

                                        <div>
                                            <Typography fontSize={14} >Confirm password</Typography>
                                            <Paper className={classes.paperBase}>
                                                <Box className={classes.inpIcon}>
                                                    <CiLock size={20} color="#9fa1a3" />
                                                </Box>
                                                <InputBase
                                                    className={classes.input}
                                                    value={pass2}
                                                    type={showPass2 ? "text" : "password"}
                                                    onChange={(e) => setPass2(e.target.value?.trim())}
                                                />
                                                <Box className={classes.eye}>
                                                    <IconButton onClick={() => setShowPass2(!showPass2)}>
                                                        {showPass2 ? <BsEyeSlash size={18} /> : <BsEyeFill size={18} />}
                                                    </IconButton>
                                                </Box>
                                            </Paper>
                                            <FormHelperText error={error} >{error}</FormHelperText>
                                        </div>

                                        <Button
                                            type='submit'
                                            disabled={btnLoading || !pass1 || !pass2}
                                            variant="contained"
                                        >
                                            {btnLoading ?
                                                <Stack direction={"row"}>
                                                    <Typography>please wait...</Typography>
                                                </Stack>
                                                : <span style={{ fontWeight: "bold", color: 'white' }}>
                                                    Reset password
                                                </span>}
                                        </Button>

                                        <Button
                                            variant="text"
                                            sx={{ textTransform: "none" }}
                                            onClick={() => navigate("/")}
                                        >
                                            Back to login
                                        </Button>
                                    </Stack>
                                </form>
                            </Box>
                        )
                            :
                            (
                                <Box className={classes.container}>
                                    <Stack direction="column" mt={2} gap={3}>
                                        <Typography variant="h4">The password has been set</Typography>
                                        <Typography
                                            fontSize="15"
                                            color="#a5a5a5"
                                            fontWeight="small"
                                        >
                                            Please login in to continue
                                        </Typography>
                                        <form onSubmit={(e) => {
                                            e.preventDefault();
                                            navigate("/")
                                        }}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                sx={{ textTransform: "none" }}
                                            >
                                                Back to login
                                            </Button>
                                        </form>
                                    </Stack>
                                </Box>
                            )}
                </Paper>
            </Box>
        </Box>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        width: "100%",
        position: "relative"
    },
    center: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    card: {
        width: "100%",
        backgroundColor: "rgba(9, 13, 21, 0.3)",
        height: "100vh"
    },
    img: {
        height: 120,
        width: "100%",
        objectFit: "cover",
    },
    main: {
        marginTop: 50,
        overflow: "hidden",
        width: "25vw",
        [theme.breakpoints.down("lg")]: {
            width: "40vw",
        },
        [theme.breakpoints.down("md")]: {
            width: "55vw",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100vw",
        },
        "& .MuiOtpInput-TextField": {
            "& .MuiOutlinedInput-input": {
                padding: "16.5px 0px",
                width: 60
            }
        },
        "& .MuiOtpInput-Box": {
            gap: 10
        }
    },
    container: {
        width: "100%",
        padding: "60px 40px",
        backgroundColor: "#fff",
    },
    paperBase: {
        display: "flex",
        width: "100%",
        borderRadius: 8,
        border: "1px solid #d1d4d7"
    },
    inpIcon: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 50,
        borderRight: "1px solid #d1d4d7",
        backgroundColor: "#E4E5E6"
    },
    input: {
        flex: 1,
        padding: "2px 10px",
        backgroundColor: "#E8F0FE"
    },
    eye: {
        paddingRight: 10,
        backgroundColor: "#E8F0FE",
    },
}))