import React from 'react';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { capitalize } from '../utils/utilities';
import { NavigateNext as NavigateNextIcon } from "@mui/icons-material"

const CustomLink = (props) => <Link component={RouterLink} {...props} />;

const BreadcrumbTrail = ({ sx = {} }) => {
    const location = useLocation();
    const params = useParams();
    const pathnames = location.pathname.split('/').filter((x) => x);

    return (
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} sx={sx} ml={5} aria-label="breadcrumb">
            {pathnames.map((name, index) => {
                switch (name) {
                    case "inverter":
                    case "battery":
                    case "profile":
                        return null
                }
                const isParam = name.startsWith(':');
                const displayName = isParam ? params[name.slice(1)] : name;
                let routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
                const isLast = index === pathnames.length - 1;

                return isLast ? (
                    <Link style={{ textDecoration: "none" }} key={index} component={RouterLink} to={routeTo} color="inherit" aria-current="page">
                        {capitalize(displayName)}
                    </Link>
                ) : (
                    <Link style={{ textDecoration: "none" }} key={index} component={RouterLink} to={routeTo}>
                        {capitalize(displayName)}
                    </Link>
                );
            })}
        </Breadcrumbs>
    );
};

export default BreadcrumbTrail;
