import { Box, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, styled, tableCellClasses } from "@mui/material"
import { tableStyles } from "../Pages/Customers"
import Container from "./container";
import { SimCardDownload } from "@mui/icons-material";
import moment from "moment";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "white",
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
        fontWeight: 500
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
        backgroundColor: "#eafafc",
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export const DeviceList = ({ data = [], navigate, loading }) => {
    const classes = tableStyles();

    const handleNav = (item) => {
        navigate(
            `/home/customers/${item.userId}/device/${item.inverterUUID}`
        );
    }


    return (
        // <Container title="Devices">
        <Box className={classes.agentTable} style={{ width: "100%", }} px={1} >
            <TableContainer component={Paper} className="tableContainer">
                <Table >
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>
                                Device ID
                            </StyledTableCell>
                            <StyledTableCell align="center">Last activity</StyledTableCell>
                            <StyledTableCell align="center">Effeciency</StyledTableCell>
                            <StyledTableCell align="center">
                                Status
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.customerTableBody}>
                        {
                            loading ?
                                <>
                                    {
                                        Array.from({ length: 10 }, () => Math.floor(Math.random() * 40)).map((x, index) => {
                                            return (
                                                <StyledTableRow key={index}>
                                                    <StyledTableCell><Skeleton variant="rectangular" height={25} /></StyledTableCell>
                                                    <StyledTableCell><Skeleton variant="rectangular" height={25} /></StyledTableCell>
                                                    <StyledTableCell><Skeleton variant="rectangular" height={25} /></StyledTableCell>
                                                    <StyledTableCell><Skeleton variant="rectangular" height={25} /></StyledTableCell>
                                                    {/* <StyledTableCell><Skeleton variant="rectangular" height={25} /></StyledTableCell> */}
                                                </StyledTableRow>
                                            )
                                        })
                                    }
                                </>
                                :
                                data?.length ?
                                    data?.map((item, index) => (
                                        <StyledTableRow key={item.id}>
                                            <StyledTableCell >
                                                <Typography>
                                                    <a
                                                        variant="text"
                                                        onClick={() => handleNav(item)}
                                                        className="clickable"
                                                    >  {item.inverterUUID ? String(item.inverterUUID).toUpperCase() : "-"}
                                                    </a>
                                                </Typography>
                                            </StyledTableCell>

                                            <StyledTableCell align="center" >
                                                <Typography>{item.updatedAt ? moment(item.updatedAt).format("DD/MM/yyyy HH:mm") : "-"}</Typography>
                                            </StyledTableCell>

                                            <StyledTableCell align="center">
                                                <Typography>{item.efficiencyPercentage ?? "-"}</Typography>
                                            </StyledTableCell>

                                            <StyledTableCell align="center">
                                                <Box
                                                    className={classes.status}
                                                    style={{
                                                        backgroundColor:
                                                            item.status === 0 ? "#4dbd74" : "#f8cb00"
                                                    }}

                                                >
                                                    <Typography color="#fff" fontWeight={"bold"} fontSize={10}>
                                                        {item.status === 0 ? "Operational" : "Offline"}
                                                    </Typography>
                                                </Box>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                    : (
                                        <StyledTableRow>
                                            {["", "No records", "", ""].map(content => <StyledTableCell >{content}</StyledTableCell>)}
                                        </StyledTableRow>
                                    )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
        // </Container>
    )
}


export const InvoiceList = ({ data = [], loading }) => {
    const classes = tableStyles();

    const handleDownloadBill = async (url) => {
        // await downloadBill(custId, billId)
        window.open(url, "_blank")
    }

    return (
        // <Container title="Devices">
        <Box className={classes.agentTable} style={{ width: "100%", }} px={1} >
            <TableContainer component={Paper} className="tableContainer">
                <Table >
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>
                                Invoice ID
                            </StyledTableCell>
                            <StyledTableCell align="center">Start Date</StyledTableCell>
                            <StyledTableCell align="center">End Date</StyledTableCell>
                            <StyledTableCell align="center">Amount</StyledTableCell>
                            <StyledTableCell align="center">Amount Due</StyledTableCell>
                            <StyledTableCell align="center">
                                Status
                            </StyledTableCell>
                            <StyledTableCell align="center">Download</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.customerTableBody}>
                        {
                            loading ?
                                <>
                                    {
                                        Array.from({ length: 10 }, () => Math.floor(Math.random() * 40)).map((x, index) => {
                                            return (
                                                <StyledTableRow key={index}>
                                                    <StyledTableCell><Skeleton variant="rectangular" height={25} /></StyledTableCell>
                                                    <StyledTableCell><Skeleton variant="rectangular" height={25} /></StyledTableCell>
                                                    <StyledTableCell><Skeleton variant="rectangular" height={25} /></StyledTableCell>
                                                    <StyledTableCell><Skeleton variant="rectangular" height={25} /></StyledTableCell>
                                                    <StyledTableCell><Skeleton variant="rectangular" height={25} /></StyledTableCell>
                                                    <StyledTableCell><Skeleton variant="rectangular" height={25} /></StyledTableCell>
                                                    <StyledTableCell><Skeleton variant="rectangular" height={25} /></StyledTableCell>
                                                </StyledTableRow>
                                            )
                                        })
                                    }
                                </>
                                :
                                data?.length ?
                                    data?.map((item, index) => (
                                        <StyledTableRow key={item.id}>
                                            <StyledTableCell >
                                                <Typography>
                                                    {String(item.paymentInvoiceId).toUpperCase()}
                                                </Typography>
                                            </StyledTableCell>

                                            <StyledTableCell align="center" >
                                                <Typography>{item.periodStart ? moment(item.periodStart).format("DD/MM/yyyy") : "-"}</Typography>
                                            </StyledTableCell>

                                            <StyledTableCell align="center">
                                                <Typography>{item.periodEnd ? moment(item.periodEnd).format("DD/MM/yyyy") : "-"}</Typography>
                                            </StyledTableCell>

                                            <StyledTableCell align="center">
                                                <Typography>
                                                    ${item.amount}
                                                </Typography>
                                            </StyledTableCell>

                                            <StyledTableCell align="center">
                                                <Typography>
                                                    ${item.amountDue}
                                                </Typography>
                                            </StyledTableCell>

                                            <StyledTableCell align="center">
                                                <Box
                                                    className={classes.status}
                                                    style={{
                                                        backgroundColor:
                                                            item.status === "paid" ? "#4dbd74" : "#f8cb00"
                                                    }}

                                                >
                                                    <Typography color="#fff" fontWeight={"bold"} fontSize={10}>
                                                        {item.status === "paid" ? "Paid" : "Due"}
                                                    </Typography>
                                                </Box>
                                            </StyledTableCell>
                                            <StyledTableCell align="center">
                                                <IconButton onClick={() => { handleDownloadBill(item.invoicePDFUrl) }}>
                                                    <SimCardDownload color="primary" />
                                                </IconButton>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    ))
                                    : (
                                        <StyledTableRow>
                                            {["", "No records", "", "", "", "",].map(content => <StyledTableCell >{content}</StyledTableCell>)}
                                        </StyledTableRow>
                                    )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
        // </Container>
    )
}