import { createSlice } from '@reduxjs/toolkit';
import { fetchDevices, fetchInverters } from '../Api/homeApi';


const initialState = {
    records: [],
    count: 0,
    loading: true,
};

const slice = createSlice({
    name: 'batteries',
    initialState,
    reducers: {
        setRecords(state, payload) {
            state.records = payload.payload.data;
            state.count = payload.payload.count
            return state;
        },
        setLoading(state, payload) {
            state.loading = payload.payload;
            return state;
        },
    }
});



/**
 * Function to get all customers based on the parameters
 * @param page : Page Number
 * @param perPage  : records per page
 * @param sortingMode : asc , desc
 * @param search : search term 
 * @returns 
 */

export const getDevices = (token) => async (dispatch) => {
    try {
        dispatch(slice.actions.setLoading(true));
        fetchDevices(token).then(async (devices) => {
            let data = [];
            data = Array.isArray(devices) ? [...devices] : []
            data = data.map(item => {
                return {
                    ...item,
                    type: 1
                }
            })

            const inverterRes = await fetchInverters(token)
            if (Array.isArray(inverterRes)) {
                const inverters = inverterRes.map((item) => {
                    return {
                        type: 2,
                        deviceId: item.id,
                        name: item.id,
                        status: item.desireMode === "standby" ? 1 : 0,
                        efficiency: item.efficiency
                    }
                })
                if (inverters.length) data = [...data, ...inverters]
            }
            dispatch(slice.actions.setRecords({
                data: data,
                count: data.length
            }))
            dispatch(slice.actions.setLoading(false));
        });
    } catch (e) {
        Show.error(e, "Error while fetching average price")
        dispatch(slice.actions.setLoading(false));
    }
}


export const batteriesReducer = slice.reducer;
export default slice;