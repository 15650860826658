import React from "react";
import { useContext } from "react";
import { Context as AppContext } from "./context/AppContext";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Login from "./Pages/Login";
import Home from "./Pages/Home";
import Batteries from "./Pages/Batteries";
import Battery from "./Pages/Battery";
import SignAAD from './Pages/SignAAD';
import Inverter from "./Pages/Inverter";
import Customers from "./Pages/Customers";
import CustomerDetails from "./Pages/CustomerDetails";
import { ForgotPassEmailPage, ForgotPassOTPPage } from "./Pages/forgotPassword";
import Profile from "./Pages/Profile";

const Navigation = () => {
  const { state, signout } = useContext(AppContext);
  let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

  return (
    <Router>
      <Routes>
        <Route
          path='/sign-oidc'
          element={<SignAAD />}
        />
        <Route
          path=""
          element={!currentUser ? <Login /> : <Navigate to="/home" />}
        />
        <Route
          path="forgot-password/email"
          element={!currentUser ? <ForgotPassEmailPage /> : <Navigate to="/home" />}
        />
        <Route
          path="forgot-password/verify"
          element={!currentUser ? <ForgotPassOTPPage /> : <Navigate to="/home" />}
        />
        <Route
          path="home"
          element={currentUser ? <Home /> : <Navigate to="/" />}
        />
        <Route
          path="home/batteries"
          element={currentUser ? <Batteries /> : <Navigate to="/" />}
        />
        <Route
          path="home/customers"
          element={currentUser ? <Customers /> : <Navigate to="/" />}
        />
        <Route
          path="home/customers/profile/:id"
          element={currentUser ? <Profile /> : <Navigate to="/" />}
        />
        <Route
          path="home/batteries/battery/:id"
          element={currentUser ? <Battery /> : <Navigate to="/" />}
        />
        <Route
          path="home/batteries/inverter/:deviceId"
          element={currentUser ? <Inverter /> : <Navigate to="/" />}
        />
        <Route
          path="home/customers/:id"
          element={currentUser ? <CustomerDetails /> : <Navigate to="/" />}
        />
        <Route
          path="home/customers/:id/device/:deviceId"
          element={currentUser ? <Inverter /> : <Navigate to="/" />}
        />
      </Routes>
    </Router>
  );
};

export default Navigation;
