import { createTheme } from "@mui/material";
import { blue, green, purple } from "@mui/material/colors";

const theme = createTheme({
    palette: {
        primary: {
            main: "#06B6D4",
            dark: "#164E63",
            light: "#D1D4D7",
            contrastText: '#fff',
        },
        secondary: {
            main: "#2A2C36",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none"
                }
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    fontFamily: "NeurialGrotesk"
                }
            }
        }
    }
});

export default theme