import PlaceIcon from '@mui/icons-material/Place';
import { Typography } from '@mui/material';
import { useState } from 'react';

const k_width = 40

export default ({ address }) => {
    const [show, setShow] = useState(true)
    const text = address ?? ""
    const textLeft = - (text.length * 2.9) - 10

    const greatPlaceStyle = {
        position: 'absolute',
        width: k_width,
        color: "red",
        left: -k_width / 2,
        top: -k_width / 2,
    };

    const addressStyle = {
        display: show ? "block" : "none",
        marginTop: 5,
        position: "absolute",
        left: textLeft,
        fontSize: 12,
        color: "white",
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        padding: "2px 5px",
        borderRadius: 25
    }

    return (
        <div onClick={() => setShow(!show)}>
            <div style={greatPlaceStyle}  >
                <PlaceIcon fontSize='large' color='inherit' />
            </div>
            <br />
            <Typography style={addressStyle} >{text}</Typography>
        </div>
    )
}