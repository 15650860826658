import axios from "axios";


// import * as moment from "moment";

let url = "https://webapi.chargesync.com/api";
// let url = "https://chargesyncadminwebapi.azurewebsites.net/api";
let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
let token = currentUser && currentUser.Token;

//#region mock data
const portfolioChargeState = {
  soC: 0.5400000214576721,
  capacity: 5,
  measuredAt: "2023-05-28T14:00:00+00:00",
  storedEnergy: 2.7,
  availableExport: 1.998,
  deviceCount: 1,
  id: 86301,
  createdAt: "2023-05-28T13:30:04.8892218+00:00",
  updatedAt: null,
  version: "AAAAAAI2LIw=",
};

const latestMarketPrice = {
  periodFrom: "2023-05-30T09:00:00+00:00",
  periodTo: "2023-05-30T09:30:00+00:00",
  price: 177.45000000000002,
  id: 0,
  createdAt: "2023-05-30T08:55:07.8530398+00:00",
  updatedAt: null,
  version: null,
};

const devices = [
  {
    id: 273,
    deviceId: "d7b5c",
    userId: "803ef0b3-fd6a-43eb-b975-9942fc9b604c",
    address: "PE28 4XP",
    longitude: -1.314738,
    latitude: 51.069806,
    lastActivityTime: "2023-05-31T05:30:04.32261+00:00",
    capacity: 5,
    chargeRate: 0,
    dischargeRate: 0,
    efficiency: 0.74,
    totalImportKWh: 3904.580078125,
    totalExportKWh: 2322.9970703125,
    soC: 0.45,
    status: 0,
    storQubeID: "SQ17090004",
    supplierId: "EC406700-D4FE-409B-B673-4386BAD82430",
    distributorId: 20,
    createdAt: "0001-01-01T00:00:00+00:00",
    updatedAt: null,
    version: "AAAAAAI2t/E=",
  },
  {
    id: 274,
    deviceId: "w6c5h",
    userId: "1e1ec485-47ab-4949-905e-cf1690f7b398",
    address: "NR3 2RB",
    longitude: -1.314738,
    latitude: 51.069806,
    lastActivityTime: "2021-06-10T20:30:03.573739+00:00",
    capacity: 2.5,
    chargeRate: 0,
    dischargeRate: 0,
    efficiency: 0.74,
    totalImportKWh: 881.9400024414062,
    totalExportKWh: 570.9600219726562,
    soC: 0.71,
    status: 1,
    storQubeID: "SQ17090006",
    supplierId: "EC406700-D4FE-409B-B673-4386BAD82430",
    distributorId: 20,
    createdAt: "0001-01-01T00:00:00+00:00",
    updatedAt: null,
    version: "AAAAAAI2t/Q=",
  },
  {
    id: 275,
    deviceId: "c8b7r",
    userId: "1e1ec485-47ab-4949-905e-cf1690f7b398",
    address: "NR6 5EE",
    longitude: -1.314738,
    latitude: 51.069806,
    lastActivityTime: "2021-09-09T01:00:01.511732+00:00",
    capacity: 5,
    chargeRate: 0,
    dischargeRate: 0,
    efficiency: 0.74,
    totalImportKWh: 409.82501220703125,
    totalExportKWh: 224.5780029296875,
    soC: 0.91,
    status: 1,
    storQubeID: "SQ17090007",
    supplierId: "EC406700-D4FE-409B-B673-4386BAD82430",
    distributorId: 20,
    createdAt: "0001-01-01T00:00:00+00:00",
    updatedAt: null,
    version: "AAAAAAI2t/U=",
  },
  {
    id: 276,
    deviceId: "f1d3p",
    userId: "803ef0b3-fd6a-43eb-b975-9942fc9b604c",
    address: "PE28 4XP",
    longitude: -1.314738,
    latitude: 51.069806,
    lastActivityTime: "2023-01-14T10:49:53.911521+00:00",
    capacity: 2.5,
    chargeRate: 0,
    dischargeRate: 0,
    efficiency: 0.74,
    totalImportKWh: 914.2579956054688,
    totalExportKWh: 740.291015625,
    soC: 0.7,
    status: 1,
    storQubeID: "SQ17090005",
    supplierId: "EC406700-D4FE-409B-B673-4386BAD82430",
    distributorId: 20,
    createdAt: "0001-01-01T00:00:00+00:00",
    updatedAt: null,
    version: "AAAAAAI2t/Y=",
  },
];

const singleDevice = {
  id: 273,
  deviceId: "d7b5c",
  userId: "803ef0b3-fd6a-43eb-b975-9942fc9b604c",
  address: "PE28 4XP",
  longitude: -1.314738,
  latitude: 51.069806,
  lastActivityTime: "2023-05-31T05:30:04.32261+00:00",
  capacity: 5,
  chargeRate: 0,
  dischargeRate: 0,
  efficiency: 0.74,
  totalImportKWh: 3904.580078125,
  totalExportKWh: 2322.9970703125,
  soC: 0.45,
  status: 0,
  storQubeID: "SQ17090004",
  supplierId: "EC406700-D4FE-409B-B673-4386BAD82430",
  distributorId: 20,
  createdAt: "0001-01-01T00:00:00+00:00",
  updatedAt: null,
  version: "AAAAAAI2t/E=",
};

const singleDevideChargeState = {
  deviceId: "d7b5c",
  soC: 0.44999998807907104,
  capacity: 5,
  measuredAt: "2023-05-31T05:30:00+00:00",
  storedEnergy: 2.25,
  availableExport: 1.665,
  id: 156990,
  createdAt: "2023-05-31T05:00:03.9218624+00:00",
  updatedAt: null,
  version: "AAAAAAI2t/I=",
};

const singleDeviceChargeStatesFromTo = [
  {
    deviceId: "d7b5c",
    soC: 0.10000000149011612,
    capacity: 0,
    measuredAt: "2023-05-31T00:00:00+00:00",
    storedEnergy: 0,
    availableExport: 0,
    id: 0,
    createdAt: "2023-05-31T00:00:00+00:00",
    updatedAt: "2023-05-31T00:00:00",
    version: null,
  },
  {
    deviceId: "d7b5c",
    soC: 0.10000000149011612,
    capacity: 0,
    measuredAt: "2023-05-31T01:00:00+00:00",
    storedEnergy: 0,
    availableExport: 0,
    id: 0,
    createdAt: "2023-05-31T01:00:00+00:00",
    updatedAt: "2023-05-31T01:00:00",
    version: null,
  },
  {
    deviceId: "d7b5c",
    soC: 0.10000000149011612,
    capacity: 0,
    measuredAt: "2023-05-31T02:00:00+00:00",
    storedEnergy: 0,
    availableExport: 0,
    id: 0,
    createdAt: "2023-05-31T02:00:00+00:00",
    updatedAt: "2023-05-31T02:00:00",
    version: null,
  },
  {
    deviceId: "d7b5c",
    soC: 0.10000000149011612,
    capacity: 0,
    measuredAt: "2023-05-31T03:00:00+00:00",
    storedEnergy: 0,
    availableExport: 0,
    id: 0,
    createdAt: "2023-05-31T03:00:00+00:00",
    updatedAt: "2023-05-31T03:00:00",
    version: null,
  },
  {
    deviceId: "d7b5c",
    soC: 0.10000000149011612,
    capacity: 0,
    measuredAt: "2023-05-31T04:00:00+00:00",
    storedEnergy: 0,
    availableExport: 0,
    id: 0,
    createdAt: "2023-05-31T04:00:00+00:00",
    updatedAt: "2023-05-31T04:00:00",
    version: null,
  },
  {
    deviceId: "d7b5c",
    soC: 0.4699999988079071,
    capacity: 0,
    measuredAt: "2023-05-31T05:00:00+00:00",
    storedEnergy: 0,
    availableExport: 0,
    id: 0,
    createdAt: "2023-05-31T05:00:00+00:00",
    updatedAt: "2023-05-31T05:00:00",
    version: null,
  },
  {
    deviceId: "d7b5c",
    soC: 0.4300000071525574,
    capacity: 0,
    measuredAt: "2023-05-31T06:00:00+00:00",
    storedEnergy: 0,
    availableExport: 0,
    id: 0,
    createdAt: "2023-05-31T06:00:00+00:00",
    updatedAt: "2023-05-31T06:00:00",
    version: null,
  },
];

const singleDeviceCashFlowsFromTo = [
  {
    deviceId: "d7b5c",
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T00:00:00+00:00",
    cashFlow: -0.2723206787109375,
    id: 0,
    createdAt: "2023-05-31T00:40:01.153844+00:00",
    updatedAt: null,
    version: null,
  },
  {
    deviceId: "d7b5c",
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T01:00:00+00:00",
    cashFlow: -0.250528564453125,
    id: 0,
    createdAt: "2023-05-31T01:40:00.6656812+00:00",
    updatedAt: null,
    version: null,
  },
  {
    deviceId: "d7b5c",
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T02:00:00+00:00",
    cashFlow: -0.26190527343750003,
    id: 0,
    createdAt: "2023-05-31T02:40:01.1360437+00:00",
    updatedAt: null,
    version: null,
  },
  {
    deviceId: "d7b5c",
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T03:00:00+00:00",
    cashFlow: -0.2887166748046875,
    id: 0,
    createdAt: "2023-05-31T03:40:01.1133884+00:00",
    updatedAt: null,
    version: null,
  },
  {
    deviceId: "d7b5c",
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T04:00:00+00:00",
    cashFlow: -13.214449951171874,
    id: 0,
    createdAt: "2023-05-31T04:40:01.1452463+00:00",
    updatedAt: null,
    version: null,
  },
  {
    deviceId: "d7b5c",
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T05:00:00+00:00",
    cashFlow: 0.4435301513671875,
    id: 0,
    createdAt: "2023-05-31T05:40:01.1466035+00:00",
    updatedAt: null,
    version: null,
  },
];

const singleDeviceCarbonFlowFromTo = [
  {
    deviceId: "d7b5c",
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T00:00:00+00:00",
    carbonFlow: -3.0077226869364813,
    id: 0,
    createdAt: "2023-05-31T00:40:01.153844+00:00",
    updatedAt: null,
    version: null,
  },
  {
    deviceId: "d7b5c",
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T01:00:00+00:00",
    carbonFlow: -3.0175328799165957,
    id: 0,
    createdAt: "2023-05-31T01:40:00.6656812+00:00",
    updatedAt: null,
    version: null,
  },
  {
    deviceId: "d7b5c",
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T02:00:00+00:00",
    carbonFlow: -3.0596078439286787,
    id: 0,
    createdAt: "2023-05-31T02:40:01.1360437+00:00",
    updatedAt: null,
    version: null,
  },
  {
    deviceId: "d7b5c",
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T03:00:00+00:00",
    carbonFlow: -3.1899137684546313,
    id: 0,
    createdAt: "2023-05-31T03:40:01.1133884+00:00",
    updatedAt: null,
    version: null,
  },
  {
    deviceId: "d7b5c",
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T04:00:00+00:00",
    carbonFlow: -189.23610569132398,
    id: 0,
    createdAt: "2023-05-31T04:40:01.1452463+00:00",
    updatedAt: null,
    version: null,
  },
  {
    deviceId: "d7b5c",
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T05:00:00+00:00",
    carbonFlow: 4.5556286527224525,
    id: 0,
    createdAt: "2023-05-31T05:40:01.1466035+00:00",
    updatedAt: null,
    version: null,
  },
];

const singleDeviceEnergyFlowFromTo = [
  {
    deviceId: null,
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T00:00:00+00:00",
    netImport: 0.02001953125,
    id: 0,
    createdAt: "2023-05-31T00:30:03.601971+00:00",
    updatedAt: null,
    version: null,
    systemEfficiency: 0,
  },
  {
    deviceId: null,
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T01:00:00+00:00",
    netImport: 0.019775390625,
    id: 0,
    createdAt: "2023-05-31T01:30:01.544191+00:00",
    updatedAt: null,
    version: null,
    systemEfficiency: 0,
  },
  {
    deviceId: null,
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T02:00:00+00:00",
    netImport: 0.02001953125,
    id: 0,
    createdAt: "2023-05-31T02:30:04.492273+00:00",
    updatedAt: null,
    version: null,
    systemEfficiency: 0,
  },
  {
    deviceId: null,
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T03:00:00+00:00",
    netImport: 0.02001953125,
    id: 0,
    createdAt: "2023-05-31T03:30:02.536323+00:00",
    updatedAt: null,
    version: null,
    systemEfficiency: 0,
  },
  {
    deviceId: null,
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T04:00:00+00:00",
    netImport: 1.170166015625,
    id: 0,
    createdAt: "2023-05-31T04:30:01.161825+00:00",
    updatedAt: null,
    version: null,
    systemEfficiency: 0,
  },
  {
    deviceId: null,
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T05:00:00+00:00",
    netImport: -0.08203125,
    id: 0,
    createdAt: "2023-05-31T05:30:04.32261+00:00",
    updatedAt: null,
    version: null,
    systemEfficiency: 0,
  },
];

const singleDeviceStatusUpdateFromTo = [
  {
    statusUpdate: 40,
    reportedAt: "2020-01-29T06:00:11.4187179+00:00",
    deviceId: "d7b5c",
    id: 64153,
    createdAt: "2020-01-29T06:00:05.6081142+00:00",
    updatedAt: null,
    version: "AAAAAAEp9HE=",
  },
  {
    statusUpdate: 50,
    reportedAt: "2020-01-29T05:56:11.0463372+00:00",
    deviceId: "d7b5c",
    id: 64152,
    createdAt: "2020-01-29T05:55:39.6691711+00:00",
    updatedAt: null,
    version: "AAAAAAEp9GY=",
  },
  {
    statusUpdate: 40,
    reportedAt: "2020-01-29T05:55:39.2489338+00:00",
    deviceId: "d7b5c",
    id: 64151,
    createdAt: "2020-01-29T05:54:43.6553027+00:00",
    updatedAt: null,
    version: "AAAAAAEp9GU=",
  },
  {
    statusUpdate: 50,
    reportedAt: "2020-01-29T05:54:42.9566027+00:00",
    deviceId: "d7b5c",
    id: 64150,
    createdAt: "2020-01-29T05:30:07.4174545+00:00",
    updatedAt: null,
    version: "AAAAAAEp9GE=",
  },
  {
    statusUpdate: 40,
    reportedAt: "2020-01-29T05:30:05.6516116+00:00",
    deviceId: "d7b5c",
    id: 64149,
    createdAt: "2020-01-29T05:30:04.0756108+00:00",
    updatedAt: null,
    version: "AAAAAAEp8zE=",
  },
  {
    statusUpdate: 50,
    reportedAt: "2020-01-29T05:12:48.7594826+00:00",
    deviceId: "d7b5c",
    id: 64148,
    createdAt: "2020-01-29T05:12:25.2410845+00:00",
    updatedAt: null,
    version: "AAAAAAEp8yg=",
  },
  {
    statusUpdate: 40,
    reportedAt: "2020-01-29T05:12:24.7129372+00:00",
    deviceId: "d7b5c",
    id: 64147,
    createdAt: "2020-01-29T05:11:17.7101005+00:00",
    updatedAt: null,
    version: "AAAAAAEp8yc=",
  },
  {
    statusUpdate: 50,
    reportedAt: "2020-01-29T05:11:17.1325971+00:00",
    deviceId: "d7b5c",
    id: 64146,
    createdAt: "2020-01-29T05:02:56.5101112+00:00",
    updatedAt: null,
    version: "AAAAAAEp8yQ=",
  },
  {
    statusUpdate: 40,
    reportedAt: "2020-01-29T05:02:56.0970385+00:00",
    deviceId: "d7b5c",
    id: 64145,
    createdAt: "2020-01-29T05:00:09.8553951+00:00",
    updatedAt: null,
    version: "AAAAAAEp8xw=",
  },
  {
    statusUpdate: 50,
    reportedAt: "2020-01-29T05:00:08.6160225+00:00",
    deviceId: "d7b5c",
    id: 64144,
    createdAt: "2020-01-29T05:00:05.1522446+00:00",
    updatedAt: null,
    version: "AAAAAAEp8fQ=",
  },
];

const singleDevicePlannedImportsFromTo = [
  {
    deviceId: "d7b5c",
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T00:00:00+00:00",
    netImport: 0,
    id: 0,
    createdAt: "2023-05-31T00:30:03.601971+00:00",
    updatedAt: null,
    version: null,
  },
  {
    deviceId: "d7b5c",
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T01:00:00+00:00",
    netImport: 0,
    id: 0,
    createdAt: "2023-05-31T01:30:01.544191+00:00",
    updatedAt: null,
    version: null,
  },
  {
    deviceId: "d7b5c",
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T02:00:00+00:00",
    netImport: 0,
    id: 0,
    createdAt: "2023-05-31T02:30:04.492273+00:00",
    updatedAt: null,
    version: null,
  },
  {
    deviceId: "d7b5c",
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T03:00:00+00:00",
    netImport: 0,
    id: 0,
    createdAt: "2023-05-31T03:30:02.536323+00:00",
    updatedAt: null,
    version: null,
  },
  {
    deviceId: "d7b5c",
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T04:00:00+00:00",
    netImport: 1.149999976158142,
    id: 0,
    createdAt: "2023-05-31T04:30:01.161825+00:00",
    updatedAt: null,
    version: null,
  },
  {
    deviceId: "d7b5c",
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T05:00:00+00:00",
    netImport: -0.08700000122189522,
    id: 0,
    createdAt: "2023-05-31T05:30:04.32261+00:00",
    updatedAt: null,
    version: null,
  },
];

const singleDeviceOptimisationPricesFromTo = [
  {
    deviceId: null,
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T00:00:00+00:00",
    price: 136.0275,
    id: 0,
    createdAt: "2023-05-30T23:55:01.1359651+00:00",
    updatedAt: null,
    version: null,
  },
  {
    deviceId: null,
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T01:00:00+00:00",
    price: 126.6825,
    id: 0,
    createdAt: "2023-05-31T00:55:01.165457+00:00",
    updatedAt: null,
    version: null,
  },
  {
    deviceId: null,
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T02:00:00+00:00",
    price: 130.095,
    id: 0,
    createdAt: "2023-05-31T01:55:01.1268329+00:00",
    updatedAt: null,
    version: null,
  },
  {
    deviceId: null,
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T03:00:00+00:00",
    price: 144.2175,
    id: 0,
    createdAt: "2023-05-31T02:55:01.1557358+00:00",
    updatedAt: null,
    version: null,
  },
  {
    deviceId: null,
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T04:00:00+00:00",
    price: 115.9725,
    id: 0,
    createdAt: "2023-05-31T03:55:01.1557011+00:00",
    updatedAt: null,
    version: null,
  },
  {
    deviceId: null,
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T05:00:00+00:00",
    price: 184.1175,
    id: 0,
    createdAt: "2023-05-31T04:55:01.1477347+00:00",
    updatedAt: null,
    version: null,
  },
];

const marketPricesFromTo = [
  {
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T00:00:00+00:00",
    price: 136.0275,
    id: 0,
    createdAt: "2023-05-30T23:55:01.1359651+00:00",
    updatedAt: null,
    version: null,
  },
  {
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T01:00:00+00:00",
    price: 126.6825,
    id: 0,
    createdAt: "2023-05-31T00:55:01.165457+00:00",
    updatedAt: null,
    version: null,
  },
  {
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T02:00:00+00:00",
    price: 130.095,
    id: 0,
    createdAt: "2023-05-31T01:55:01.1268329+00:00",
    updatedAt: null,
    version: null,
  },
  {
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T03:00:00+00:00",
    price: 144.2175,
    id: 0,
    createdAt: "2023-05-31T02:55:01.1557358+00:00",
    updatedAt: null,
    version: null,
  },
  {
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T04:00:00+00:00",
    price: 115.9725,
    id: 0,
    createdAt: "2023-05-31T03:55:01.1557011+00:00",
    updatedAt: null,
    version: null,
  },
  {
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T05:00:00+00:00",
    price: 184.1175,
    id: 0,
    createdAt: "2023-05-31T04:55:01.1477347+00:00",
    updatedAt: null,
    version: null,
  },
];

const portFolioChargeStates = [
  {
    soC: 0.10000000149011612,
    capacity: 0,
    measuredAt: "2023-05-31T00:00:00+00:00",
    storedEnergy: 0,
    availableExport: 0,
    deviceCount: 0,
    id: 0,
    createdAt: "2023-05-31T00:00:00+00:00",
    updatedAt: "2023-05-31T00:00:00",
    version: null,
  },
  {
    soC: 0.10000000149011612,
    capacity: 0,
    measuredAt: "2023-05-31T01:00:00+00:00",
    storedEnergy: 0,
    availableExport: 0,
    deviceCount: 0,
    id: 0,
    createdAt: "2023-05-31T01:00:00+00:00",
    updatedAt: "2023-05-31T01:00:00",
    version: null,
  },
  {
    soC: 0.10000000149011612,
    capacity: 0,
    measuredAt: "2023-05-31T02:00:00+00:00",
    storedEnergy: 0,
    availableExport: 0,
    deviceCount: 0,
    id: 0,
    createdAt: "2023-05-31T02:00:00+00:00",
    updatedAt: "2023-05-31T02:00:00",
    version: null,
  },
  {
    soC: 0.10000000149011612,
    capacity: 0,
    measuredAt: "2023-05-31T03:00:00+00:00",
    storedEnergy: 0,
    availableExport: 0,
    deviceCount: 0,
    id: 0,
    createdAt: "2023-05-31T03:00:00+00:00",
    updatedAt: "2023-05-31T03:00:00",
    version: null,
  },
  {
    soC: 0.10000000149011612,
    capacity: 0,
    measuredAt: "2023-05-31T04:00:00+00:00",
    storedEnergy: 0,
    availableExport: 0,
    deviceCount: 0,
    id: 0,
    createdAt: "2023-05-31T04:00:00+00:00",
    updatedAt: "2023-05-31T04:00:00",
    version: null,
  },
  {
    soC: 0.4699999988079071,
    capacity: 0,
    measuredAt: "2023-05-31T05:00:00+00:00",
    storedEnergy: 0,
    availableExport: 0,
    deviceCount: 0,
    id: 0,
    createdAt: "2023-05-31T05:00:00+00:00",
    updatedAt: "2023-05-31T05:00:00",
    version: null,
  },
  {
    soC: 0.4300000071525574,
    capacity: 0,
    measuredAt: "2023-05-31T06:00:00+00:00",
    storedEnergy: 0,
    availableExport: 0,
    deviceCount: 0,
    id: 0,
    createdAt: "2023-05-31T06:00:00+00:00",
    updatedAt: "2023-05-31T06:00:00",
    version: null,
  },
];

const portfolioCashflowsFromTo = [
  {
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T00:00:00+00:00",
    cashFlow: -0.2723206787109375,
    id: 0,
    createdAt: "2023-05-31T00:40:01.153844+00:00",
    updatedAt: null,
    version: null,
  },
  {
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T01:00:00+00:00",
    cashFlow: -0.250528564453125,
    id: 0,
    createdAt: "2023-05-31T01:40:00.6656812+00:00",
    updatedAt: null,
    version: null,
  },
  {
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T02:00:00+00:00",
    cashFlow: -0.26190527343750003,
    id: 0,
    createdAt: "2023-05-31T02:40:01.1360437+00:00",
    updatedAt: null,
    version: null,
  },
  {
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T03:00:00+00:00",
    cashFlow: -0.2887166748046875,
    id: 0,
    createdAt: "2023-05-31T03:40:01.1133884+00:00",
    updatedAt: null,
    version: null,
  },
  {
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T04:00:00+00:00",
    cashFlow: -13.214449951171874,
    id: 0,
    createdAt: "2023-05-31T04:40:01.1452463+00:00",
    updatedAt: null,
    version: null,
  },
  {
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T05:00:00+00:00",
    cashFlow: 1.5530284423828122,
    id: 0,
    createdAt: "2023-05-31T05:40:01.1466035+00:00",
    updatedAt: null,
    version: null,
  },
];

const portfolioCarbonFlowsFromTo = [
  {
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T00:00:00+00:00",
    carbonFlow: -3.0077226869364813,
    id: 0,
    createdAt: "2023-05-31T00:40:01.153844+00:00",
    updatedAt: null,
    version: null,
  },
  {
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T01:00:00+00:00",
    carbonFlow: -3.0175328799165957,
    id: 0,
    createdAt: "2023-05-31T01:40:00.6656812+00:00",
    updatedAt: null,
    version: null,
  },
  {
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T02:00:00+00:00",
    carbonFlow: -3.0596078439286787,
    id: 0,
    createdAt: "2023-05-31T02:40:01.1360437+00:00",
    updatedAt: null,
    version: null,
  },
  {
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T03:00:00+00:00",
    carbonFlow: -3.1899137684546313,
    id: 0,
    createdAt: "2023-05-31T03:40:01.1133884+00:00",
    updatedAt: null,
    version: null,
  },
  {
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T04:00:00+00:00",
    carbonFlow: -189.23610569132398,
    id: 0,
    createdAt: "2023-05-31T04:40:01.1452463+00:00",
    updatedAt: null,
    version: null,
  },
  {
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T05:00:00+00:00",
    carbonFlow: 14.698848625687226,
    id: 0,
    createdAt: "2023-05-31T05:40:01.1466035+00:00",
    updatedAt: null,
    version: null,
  },
];

const portFolioEnergyflowsFromTo = [
  {
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T00:00:00+00:00",
    netImport: 0.02001953125,
    systemEfficiency: 6.5625,
    import: 0,
    export: 0,
    id: 0,
    createdAt: "2023-05-31T00:30:03.601971+00:00",
    updatedAt: null,
    version: null,
  },
  {
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T01:00:00+00:00",
    netImport: 0.019775390625,
    systemEfficiency: 6.5625,
    import: 0,
    export: 0,
    id: 0,
    createdAt: "2023-05-31T01:30:01.544191+00:00",
    updatedAt: null,
    version: null,
  },
  {
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T02:00:00+00:00",
    netImport: 0.02001953125,
    systemEfficiency: 6.5625,
    import: 0,
    export: 0,
    id: 0,
    createdAt: "2023-05-31T02:30:04.492273+00:00",
    updatedAt: null,
    version: null,
  },
  {
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T03:00:00+00:00",
    netImport: 0.02001953125,
    systemEfficiency: 6.5625,
    import: 0,
    export: 0,
    id: 0,
    createdAt: "2023-05-31T03:30:02.536323+00:00",
    updatedAt: null,
    version: null,
  },
  {
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T04:00:00+00:00",
    netImport: 1.170166015625,
    systemEfficiency: 6.5625,
    import: 0,
    export: 0,
    id: 0,
    createdAt: "2023-05-31T04:30:01.161825+00:00",
    updatedAt: null,
    version: null,
  },
  {
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T05:00:00+00:00",
    netImport: -0.08203125,
    systemEfficiency: 6.5625,
    import: 0,
    export: 0,
    id: 0,
    createdAt: "2023-05-31T05:30:04.32261+00:00",
    updatedAt: null,
    version: null,
  },
];

const portfolioPlannedImportsFromTo = [
  {
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T00:00:00+00:00",
    netImport: 0,
    id: 0,
    createdAt: "2023-05-31T00:30:03.601971+00:00",
    updatedAt: null,
    version: null,
  },
  {
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T01:00:00+00:00",
    netImport: 0,
    id: 0,
    createdAt: "2023-05-31T01:30:01.544191+00:00",
    updatedAt: null,
    version: null,
  },
  {
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T02:00:00+00:00",
    netImport: 0,
    id: 0,
    createdAt: "2023-05-31T02:30:04.492273+00:00",
    updatedAt: null,
    version: null,
  },
  {
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T03:00:00+00:00",
    netImport: 0,
    id: 0,
    createdAt: "2023-05-31T03:30:02.536323+00:00",
    updatedAt: null,
    version: null,
  },
  {
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T04:00:00+00:00",
    netImport: 1.149999976158142,
    id: 0,
    createdAt: "2023-05-31T04:30:01.161825+00:00",
    updatedAt: null,
    version: null,
  },
  {
    periodFrom: "0001-01-01T00:00:00+00:00",
    periodTo: "2023-05-31T05:00:00+00:00",
    netImport: -0.08700000122189522,
    id: 0,
    createdAt: "2023-05-31T05:30:04.32261+00:00",
    updatedAt: null,
    version: null,
  },
];
//#endregion

//#region DB
export const getLatestPortfolioChargeState = async (stateToken) => {
  let res = await axios({
    method: "get",
    url: url + "/device/GetLatestPortfolioChargeState",
    headers: {
      Authorization: "Bearer " + stateToken,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => res.data)
    .catch((error) => {
      console.log(error);
      if (error.response && error.response.status == 401) {
        window.location.href = "/";
        sessionStorage.setItem("currentUser", null);
      }

    });
  return res;
};

export const getDeviceEnergyFlows = async (
  deviceId,
  from,
  to,
  chunks,
  stateToken
) => {
  from = from.format("YYYY-MM-DD 00:00:00");
  to = to.format("YYYY-MM-DD 23:59:59");

  return await axios({
    method: "get",
    url:
      url +
      "/device/GetDeviceEnergyFlows?deviceId=" +
      deviceId +
      "&fromUtc=" +
      from +
      "&toUtc=" +
      to +
      "&chunks=" +
      chunks,
    headers: {
      Authorization: "Bearer " + stateToken,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
      if (error.response && error.response.status == 401) {
        window.location.href = "/";
        sessionStorage.setItem("currentUser", null);
      }
    });
};

export const getLatestMarketPrice = async (stateToken) => {
  let res = await axios({
    method: "get",
    url: url + "/device/GetLatestMarketPrice",
    headers: {
      Authorization: "Bearer " + stateToken,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => res.data)
    .catch((error) => {
      console.log(error);
      if (error.response && error.response.status == 401) {
        window.location.href = "/";
        sessionStorage.setItem("currentUser", null);
      }
    });
  return res;
};

export const fetchDevices = async (stateToken) => {
  return await axios({
    method: "get",
    url: url + "/device",
    headers: {
      Authorization: "Bearer " + stateToken,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
      if (error.response && error.response.status == 401) {
        window.location.href = "/";
        sessionStorage.setItem("currentUser", null);
      }
    });
};

export const getDevice = async (deviceId, stateToken) => {
  return await axios({
    method: "get",
    url: url + "/device/" + deviceId,
    headers: {
      Authorization: "Bearer " + stateToken,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
      if (error.response && error.response.status == 401) {
        window.location.href = "/";
        sessionStorage.setItem("currentUser", null);
      }
    });
};

export const getLatestDeviceChargeState = async (deviceId, stateToken) => {
  return await axios({
    method: "get",
    url: url + "/device/GetLatestDeviceChargeState?deviceId=" + deviceId,
    headers: {
      Authorization: "Bearer " + stateToken,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
      if (error.response && error.response.status == 401) {
        window.location.href = "/";
        sessionStorage.setItem("currentUser", null);
      }
    });
};

export const getMarketPrices = async (from, to, chunks, stateToken) => {
  from = from.format("YYYY-MM-DD 00:00:00");
  to = to.format("YYYY-MM-DD 23:59:59");

  return await axios({
    method: "get",
    url:
      url +
      "/device/GetMarketPrices?fromUtc=" +
      from +
      "&toUtc=" +
      to +
      "&chunks=" +
      chunks,
    headers: {
      Authorization: "Bearer " + stateToken,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
    });
};

export const getPortfolioChargeStates = async (
  from,
  to,
  chunks,
  stateToken
) => {
  from = from.format("YYYY-MM-DD 00:00:00");
  to = to.format("YYYY-MM-DD 23:59:59");

  return await axios({
    method: "get",
    url:
      url +
      "/device/GetPortfolioChargeStates?fromUtc=" +
      from +
      "&toUtc=" +
      to +
      "&chunks=" +
      chunks,
    headers: {
      Authorization: "Bearer " + stateToken,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
    });
};

export const getDeviceChargeStates = async (
  deviceId,
  from,
  to,
  chunks,
  stateToken
) => {
  from = from.format("YYYY-MM-DD 00:00:00");
  to = to.format("YYYY-MM-DD 23:59:59");
  return await axios({
    method: "get",
    url:
      url +
      "/device/getDeviceChargeStates?deviceId=" +
      deviceId +
      "&fromUtc=" +
      from +
      "&toUtc=" +
      to +
      "&chunks=" +
      chunks,
    headers: {
      Authorization: "Bearer " + stateToken,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
    });
};

export const getPortfolioCashFlows = async (from, to, chunks, stateToken) => {
  from = from.format("YYYY-MM-DD 00:00:00");
  to = to.format("YYYY-MM-DD 23:59:59");

  return await axios({
    method: "get",
    url:
      url +
      "/device/GetPortfolioCashFlows?fromUtc=" +
      from +
      "&toUtc=" +
      to +
      "&chunks=" +
      chunks,
    headers: {
      Authorization: "Bearer " + stateToken,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
    });
};

export const getDeviceCashFlows = async (
  deviceId,
  from,
  to,
  chunks,
  stateToken
) => {
  from = from.format("YYYY-MM-DD 00:00:00");
  to = to.format("YYYY-MM-DD 23:59:59");

  return await axios({
    method: "get",
    url:
      url +
      "/device/getDeviceCashFlows?deviceId=" +
      deviceId +
      "&fromUtc=" +
      from +
      "&toUtc=" +
      to +
      "&chunks=" +
      chunks,
    headers: {
      Authorization: "Bearer " + stateToken,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
    });
};

export const getPortfolioCarbonFlows = async (from, to, chunks, stateToken) => {
  from = from.format("YYYY-MM-DD 00:00:00");
  to = to.format("YYYY-MM-DD 23:59:59");

  return await axios({
    method: "get",
    url:
      url +
      "/device/getPortfolioCarbonFlows?fromUtc=" +
      from +
      "&toUtc=" +
      to +
      "&chunks=" +
      chunks,
    headers: {
      Authorization: "Bearer " + stateToken,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
    });
};

export const getDeviceCarbonFlows = async (
  deviceId,
  from,
  to,
  chunks,
  stateToken
) => {
  from = from.format("YYYY-MM-DD 00:00:00");
  to = to.format("YYYY-MM-DD 23:59:59");

  return await axios({
    method: "get",
    url:
      url +
      "/device/GetDeviceCarbonFlows?deviceId=" +
      deviceId +
      "&fromUtc=" +
      from +
      "&toUtc=" +
      to +
      "&chunks=" +
      chunks,
    headers: {
      Authorization: "Bearer " + stateToken,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
    });
};

export const getPortfolioCashFlowsSum = async (from, to, stateToken) => {
  from = from.format("YYYY-MM-DD 00:00:00");
  to = to.format("YYYY-MM-DD 23:59:59");

  return await axios({
    method: "get",
    url:
      url + "/device/GetPortfolioCashFlowsSum?fromUtc=" + from + "&toUtc=" + to,
    headers: {
      Authorization: "Bearer " + stateToken,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
    });
};

export const getDeviceCashFlowsSum = async (deviceId, from, to, stateToken) => {
  from = from.format("YYYY-MM-DD 00:00:00");
  to = to.format("YYYY-MM-DD 23:59:59");

  return await axios({
    method: "get",
    url:
      url +
      "/device/GetDeviceCashFlowsSum?deviceId=" +
      deviceId +
      "&fromUtc=" +
      from +
      "&toUtc=" +
      to,
    headers: {
      Authorization: "Bearer " + stateToken,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
    });
};

export const getPortfolioCarbonFlowsSum = async (from, to, stateToken) => {
  from = from.format("YYYY-MM-DD 00:00:00");
  to = to.format("YYYY-MM-DD 23:59:59");

  return await axios({
    method: "get",
    url:
      url +
      "/device/GetPortfolioCarbonFlowsSum?fromUtc=" +
      from +
      "&toUtc=" +
      to,
    headers: {
      Authorization: "Bearer " + stateToken,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
    });
};

export const getDeviceCarbonFlowsSum = async (
  deviceId,
  from,
  to,
  stateToken
) => {
  from = from.format("YYYY-MM-DD 00:00:00");
  to = to.format("YYYY-MM-DD 23:59:59");

  return await axios({
    method: "get",
    url:
      url +
      "/device/GetDeviceCarbonFlowsSum?deviceId=" +
      deviceId +
      "&fromUtc=" +
      from +
      "&toUtc=" +
      to,
    headers: {
      Authorization: "Bearer " + stateToken,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
    });
};

export const getPortfolioEnergyFlows = async (from, to, chunks, stateToken) => {
  from = from.format("YYYY-MM-DD 00:00:00");
  to = to.format("YYYY-MM-DD 23:59:59");

  return await axios({
    method: "get",
    url:
      url +
      "/device/GetPortfolioEnergyFlows?fromUtc=" +
      from +
      "&toUtc=" +
      to +
      "&chunks=" +
      chunks,
    headers: {
      Authorization: "Bearer " + stateToken,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
    });
};

// no data yet
export const GetDeviceMeterData = async (deviceId, from, to, stateToken) => {
  from = from.format("YYYY-MM-DD 00:00:00");
  to = to.format("YYYY-MM-DD 23:59:59");

  return await axios({
    method: "get",
    url:
      url +
      "/device/GetDeviceMeterData?deviceId=" +
      deviceId +
      "&fromUtc=" +
      from +
      "&toUtc=" +
      to,
    headers: {
      Authorization: "Bearer " + stateToken,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
    });
};

export const GetDeviceAlarms = async (deviceId, from, to, stateToken) => {
  from = from.format("YYYY-MM-DD 00:00:00");
  to = to.format("YYYY-MM-DD 23:59:59");

  return await axios({
    method: "get",
    url:
      url +
      "/device/GetDeviceAlarms?deviceId=" +
      deviceId +
      "&fromUtc=" +
      from +
      "&toUtc=" +
      to,
    headers: {
      Authorization: "Bearer " + stateToken,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
    });
};

export const GetDeviceStatusUpdates = async (
  deviceId,
  from,
  to,
  stateToken
) => {
  from = from.format("YYYY-MM-DD 00:00:00");
  to = to.format("YYYY-MM-DD 23:59:59");

  return await axios({
    method: "get",
    url:
      url +
      "/device/GetDeviceStatusUpdates?deviceId=" +
      deviceId +
      "&fromUtc=" +
      from +
      "&toUtc=" +
      to,
    headers: {
      Authorization: "Bearer " + stateToken,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
    });
};

export const getPortfolioPlannedImports = async (
  from,
  to,
  chunks,
  stateToken
) => {
  from = from.format("YYYY-MM-DD 00:00:00");
  to = to.format("YYYY-MM-DD 23:59:59");

  return await axios({
    method: "get",
    url:
      url +
      "/device/GetPortfolioPlannedImports?fromUtc=" +
      from +
      "&toUtc=" +
      to +
      "&chunks=" +
      chunks,
    headers: {
      Authorization: "Bearer " + stateToken,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
    });
};

export const GetDevicePlannedImports = async (
  deviceId,
  from,
  to,
  chunks,
  stateToken
) => {
  from = from.format("YYYY-MM-DD 00:00:00");
  to = to.format("YYYY-MM-DD 23:59:59");

  return await axios({
    method: "get",
    url:
      url +
      "/device/GetDevicePlannedImports?deviceId=" +
      deviceId +
      "&fromUtc=" +
      from +
      "&toUtc=" +
      to +
      "&chunks=" +
      chunks,
    headers: {
      Authorization: "Bearer " + stateToken,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
    });
};

export const getDeviceOptimisationPrices = async (
  deviceId,
  from,
  to,
  chunks,
  stateToken
) => {
  from = from.format("YYYY-MM-DD 00:00:00");
  to = to.format("YYYY-MM-DD 23:59:59");

  return await axios({
    method: "get",
    url:
      url +
      "/device/GetDeviceOptimisationPrices?deviceId=" +
      deviceId +
      "&fromUtc=" +
      from +
      "&toUtc=" +
      to +
      "&chunks=" +
      chunks,
    headers: {
      Authorization: "Bearer " + stateToken,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => { })
    .catch((error) => {
      return singleDeviceOptimisationPricesFromTo;
    });
};

//#endregion

//#region inverters

export const fetchInverters = async (stateToken) => {
  let res = await axios({
    method: "get",
    url: url + "/Inverter/",
    headers: {
      Authorization: "Bearer " + stateToken,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => res.data)
    .catch((error) => {
      console.log(error);
      if (error.response && error.response.status == 401) {
        window.location.href = "/";
        sessionStorage.setItem("currentUser", null);
      }

    });
  return res;
};

export const getInverter = async (id, stateToken) => {
  let res = await axios({
    method: "get",
    url: url + "/Inverter/" + id,
    headers: {
      Authorization: "Bearer " + stateToken,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => res.data)
    .catch((error) => {
      console.log(error);
      if (error.response && error.response.status == 401) {
        window.location.href = "/";
        sessionStorage.setItem("currentUser", null);
      }
    });
  return res;
};

export const getInverterFlow = async (id, stateToken) => {
  let res = await axios({
    method: "get",
    url: url + "/Inverter/inverterflows/" + id,
    headers: {
      Authorization: "Bearer " + stateToken,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((res) => res.data)
  return res;
};

export const getImportPrices = async (
  from,
  to,
  stateToken
) => {
  from = from.format("YYYY-MM-DD 00:00:00");
  to = to.format("YYYY-MM-DD 23:59:59");
  return await axios({
    method: "get",
    url:
      url +
      "/Prices/import-prices?startDate=" +
      from +
      "&endDate=" +
      to,
    headers: {
      Authorization: "Bearer " + stateToken,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
    });
};

export const getExportPrices = async (
  from,
  to,
  stateToken
) => {
  from = from.format("YYYY-MM-DD 00:00:00");
  to = to.format("YYYY-MM-DD 23:59:59");
  return await axios({
    method: "get",
    url:
      url +
      "/Prices/export-prices?startDate=" +
      from +
      "&endDate=" +
      to,
    headers: {
      Authorization: "Bearer " + stateToken,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
    });
};

export const getBatteryFlow = async (
  inverterId,
  from,
  to,
  stateToken
) => {
  from = from.format("YYYY-MM-DD 00:00:00");
  to = to.format("YYYY-MM-DD 23:59:59");
  return await axios({
    method: "get",
    url:
      url +
      `/Inverter/batterflow/${inverterId}?startDate=` +
      from +
      "&endDate=" +
      to,
    headers: {
      Authorization: "Bearer " + stateToken,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
      if (error.response && error.response.status == 401) {
        window.location.href = "/";
        sessionStorage.setItem("currentUser", null);
      }
    });
};

export const getInverterDailyStatus = async (
  inverterId,
  from,
  to,
  stateToken
) => {
  from = from.format("YYYY-MM-DD 00:00:00");
  to = to.format("YYYY-MM-DD 23:59:59");
  return await axios({
    method: "get",
    url:
      url +
      `/inverter/daily-status/${inverterId}?startDate=` +
      from +
      "&endDate=" +
      to,
    headers: {
      Authorization: "Bearer " + stateToken,
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
    });
};

export const UpdateDeviceInfo = async (stateToken, userinfo, userId) => {
  return axios.post(url + `/inverter/updateInverterinfo/${userId}`,
    userinfo,
    {
      headers: {
        Authorization: "Bearer " + stateToken,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      }
    })
    .then(res => res.data);

}

//#endregion

//#region customer

export const fetchCustomers = async (stateToken, page, perPage, search, sorting) => {
  return await axios.post(url + "/user/all", {
    "sort_key": sorting.name,
    "sort_direction": sorting.mode,
    "search": search?.trim() === "" ? null : search,
    "page": page,
    "page_size": perPage
  },
    {
      headers: {
        Authorization: "Bearer " + stateToken,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      }
    })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
      if (error.response && error.response.status == 401) {
        window.location.href = "/";
        sessionStorage.setItem("currentUser", null);
      }
    });
};
export const getCustomerDetails = async (id, stateToken) => {
  return await axios.get(url + "/user/personal-info/" + id,
    {
      headers: {
        Authorization: "Bearer " + stateToken,
        "Access-Control-Allow-Origin": "*",
      }
    })
    .then((response) => response.data)
    .catch((error) => {
      console.log(error);
      if (error.response && error.response.status == 401) {
        window.location.href = "/";
        sessionStorage.setItem("currentUser", null);
      }
    });
}
// return {
//   user: {
//     address: "20 Brimsome Mdw, Highnam, Gloucester GL2 8EW, UK",
//     createdAt: "0001-01-01T00:00:00+00:00",
//     deviceId: "eed4e",
//     emailId: "test@gmail.com",
//     firstName: "Martin",
//     id: 3,
//     ipAddress: null,
//     isAdmin: false,
//     isDeviceRegistered: false,
//     isTNCAgreed: false,
//     isVerified: false,
//     paymentCustomerId: null,
//     phoneNumber: "43784678784",
//     planId: 0,
//     role: "user",
//     secondName: "Bodocky",
//     updatedAt: null,
//     userId: "803ef0b3-fd6a-43eb-b975-9942fc9b604c",
//     version: "AAAAAAJzmCQ=",
//   },
//   card: {
//     cardNumber: "************1111",
//     type: "mastercard",
//     name: "Martin Bodocky",
//     expiry: new Date()
//   },
//   subscription: {
//     type: 0,
//     dueDate: new Date(),
//     fee: 0.25,
//     planName: "Basic plan"
//   },
//   invoices: [
//     {
//       date: new Date(),
//       fee: 120,
//       type: 1,
//       transactionId: "abcd12345",
//       cardNumber: "************5454",
//       billingAddress: "20 Brimsome Mdw, Highnam, Gloucester GL2 8EW, UK",
//       url: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf'
//     }
//   ],
//   devices: [
//     {
//       chargePowerWatts: 3.140000104904175,
//       desireMode: "standby",
//       efficiency: 0.85,
//       id: "f11d095a-4c47-11ee-95c5-60fb0081c882",
//       latLong: null,
//       locationAddress: "20 Brimsome Mdw, Highnam, Gloucester GL2 8EW, UK",
//       name: "ESP1010",
//       optimizationMode: 0,
//       timeZone: "Europe/London",
//       weekEndChargeFrom: 10,
//       weekEndChargeTo: 10,
//       weekdayChargeFrom: 10,
//       weekdayChargeTo: 10,
//       weekdayDischargeFrom: 10,
//       weekdayDischargeTo: 10,
//     }
//   ]
// }
// };

//#endregion