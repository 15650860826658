import React, { useContext, useState } from "react";
import { Context as AppContext } from "../context/AppContext";
import { SlUser } from "react-icons/sl";
import { CiLock } from "react-icons/ci";

import {
  Box,
  Typography,
  Stack,
  TextField,
  InputBase,
  Button,
  Paper,
  Divider,
  IconButton,
} from "@mui/material";
import { Link, Navigate, useNavigate } from "react-router-dom";

import { loginUser, getClaims } from "../Api/loginApi";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { BsEyeFill, BsEyeSlash } from "react-icons/bs";
import CircularProgress, {
  circularProgressClasses,
} from '@mui/material/CircularProgress'

const LoadingCircularProgress = (props) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        }}
        size={20}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) => (theme.palette.mode === 'light' ? '#6ec2e9' : '#308fe8'),
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={20}
        thickness={4}
        {...props}
      />
    </Box>
  );
}

const Login = (props) => {
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [showPass, setShowPass] = useState(false)
  const classes = useStyles();
  const navigate = useNavigate();

  const { state, signin } = useContext(AppContext);

  const submit = async () => {
    setBtnLoading(true);
    let data = await loginUser(name, password);
    // console.log("data>>>>>", data);
    if (data.access_token) {
      let token = data.access_token;
      let expires_in = data.expires_in;
      await getClaims(data.access_token, data.expires_in, name);
      signin({ name, password, token, expires_in });
      navigate("/home");
    } else {
      setBtnLoading(false)
      alert("try again");
    }
  };

  return (
    <Box className={clsx(classes.center, classes.root)} >
      <Paper
        className={classes.main}
      >
        <Typography fontSize="40" fontWeight="600" color="#3d3f42">
          <img src="https://chargesyncadminwebsite.azurewebsites.net/logo_inline_black.475539816c6af0d56b4f.jpg" width="100%" />
        </Typography>

        <Typography
          fontSize="15"
          mt={1}
          color="#d1d4d7"
          fontWeight="small"
        >
          Sign In to your account
        </Typography>

        <Stack direction="column" mt={3} gap={2}>
          <Paper className={classes.paperBase}>
            <Box className={classes.inpIcon}>
              <SlUser size={16} color="#9fa1a3" />
            </Box>
            <InputBase
              className={classes.input}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Paper>

          <Paper className={classes.paperBase}>
            <Box className={classes.inpIcon}>
              <CiLock size={20} color="#9fa1a3" />
            </Box>
            <InputBase
              className={classes.input}
              value={password}
              type={showPass ? "text" : "password"}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Box className={classes.eye}>
              <IconButton onClick={() => setShowPass(!showPass)}>
                {showPass ? <BsEyeSlash size={18} /> : <BsEyeFill size={18} />}
              </IconButton>
            </Box>
          </Paper>

          <Button
            disabled={btnLoading}
            onClick={submit}
            variant="contained"
            sx={{
              fontWeight: "bold",
              color: "#fff",
              mt: "2"
            }}
          >
            {/* {btnLoading ?
              "please wait ..."
              : <span style={{ fontWeight: "bold", color: 'white' }}>
                LOGIN
              </span>} */}
            {btnLoading ?
              <Stack direction={"row"} spacing={1}  >
                <LoadingCircularProgress />
                <span>Please wait</span>
              </Stack>
              : <>LOGIN</>}
          </Button>

          <Button
            variant="text"
            sx={{ textTransform: "none" }}
            onClick={() => navigate("/forgot-password/email")}
          >
            Forgot password?
          </Button>
        </Stack>
      </Paper>
    </Box>
  );
};

export default Login;

const useStyles = makeStyles(theme => ({
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  root: {
    width: "100%",
    backgroundColor: "#A2A4A7",
    height: "100vh"
  },
  main: {
    padding: 40,
    backgroundColor: "#fff",
    width: "25vw",
    [theme.breakpoints.down("lg")]: {
      width: "40vw",
    },
    [theme.breakpoints.down("md")]: {
      width: "45vw",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100vw",
    },
  },
  paperBase: {
    display: "flex",
    width: "100%",
    borderRadius: 8,
    border: "1px solid #d1d4d7"
  },
  inpIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 50,
    borderRight: "1px solid #d1d4d7",
    backgroundColor: "#E4E5E6"
  },
  input: {
    flex: 1,
    padding: "2px 10px",
    backgroundColor: "#E8F0FE"
  },
  eye: {
    paddingRight: 10,
    backgroundColor: "#E8F0FE"
  },
}))