import { createSlice } from '@reduxjs/toolkit';
import { fetchCustomers } from '../Api/homeApi';


const initialState = {
    customers: [],
    count: 0,
    loading: true,
};

const slice = createSlice({
    name: 'customers',
    initialState,
    reducers: {
        setCustomers(state, payload) {
            state.customers = payload.payload.customers;
            state.count = payload.payload.count
            return state;
        },
        setLoading(state, payload) {
            state.loading = payload.payload;
            return state;
        },
    }
});



/**
 * Function to get all customers based on the parameters
 * @param page : Page Number
 * @param perPage  : records per page
 * @param sortingMode : asc , desc
 * @param search : search term 
 * @returns 
 */

export const getCustomers = (token, page, perPage, search, sortingMode) => async (dispatch) => {
    try {
        dispatch(slice.actions.setLoading(true));
        fetchCustomers(token, page, perPage, search, sortingMode).then(async (customers) => {
            let data = [];
            if (Array.isArray(customers?.data)) {
                data = [...customers?.data]
            }

            dispatch(slice.actions.setCustomers({ customers: data, count: customers?.count }))
            dispatch(slice.actions.setLoading(false));
        });
    } catch (e) {
        dispatch(slice.actions.setLoading(false));
        Show.error(e, "Error while fetching average price")
    }
}


export const customerReducer = slice.reducer;
export default slice;