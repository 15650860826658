import React, { useEffect, useContext, useState } from "react";
import { Context as AppContext } from "../context/AppContext";

import { Avatar, Box, Typography } from "@mui/material";
import { HiOutlineLogout } from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import { BsBatteryFull } from "react-icons/bs";
import { SlSpeedometer } from "react-icons/sl";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { BsFillPeopleFill } from "react-icons/bs";
import { GridView } from "@mui/icons-material";
import { NameAvatar, Pressable } from "./muiComponents";


const Drawer = ({ show, location }) => {
  const styles = useStyles()
  const [drawerClass, setDrawerClass] = useState(null);
  const [clicked, setClicked] = useState("portfolio");
  const currentUser = JSON.parse(sessionStorage.getItem("currentUser"));

  const navigate = useNavigate();

  const { state, signout } = useContext(AppContext);

  const handleLogout = () => {
    navigate("/");
    sessionStorage.setItem("currentUser", null);
    signout();
  };
  useEffect(() => {
    if (show) {
      setDrawerClass(null);
    } else {
      setDrawerClass(styles.sidedraweropen);
    }
  }, [show]);

  return (
    <Box
      className={clsx(styles.sidedrawer, drawerClass)}
    >
      <Box                           // Flex
        className={styles.avatar}
      >
        <NameAvatar
          size={75}
          name={currentUser?.Username ?? ""}
        />
        <Link style={{ textDecoration: "none" }} to="#" state={{ name: currentUser?.Username ?? "" }}>
          <Typography color="#fff" mt={2} fontWeight={"bold"}>
            {currentUser?.Username ?? ""}
          </Typography>
        </Link>
        <Pressable
          onPress={() => handleLogout()}
          style={{ flexDirection: "row", alignItems: "center", marginTop: 10 }}
        >
          <HiOutlineLogout size={18} color="#d1d4d7" />
        </Pressable>
      </Box>

      <Box                    // Flex
        height="70%"
        width="100%"
        style={{ flexDirection: "column" }}
        className={styles.menu}
      >
        <Typography fontSize={11} color="#d1d4d7" width="100%" p={1.5}>
          OVERVIEW
        </Typography>

        <Pressable
          onPress={() => {
            navigate("/home");
          }}
        >
          <Box                           // Flex
            backgroundColor={location === "portfolio" ? "#00000054" : "#2a2c36"}
            width="100%"
            p={1.5}
            pl={1}
            color={location === "portfolio" ? "#20a8d8" : "#fff"}
            className={styles.menuItems}
          >
            <GridView
              size={14}
              color="inherit"
            />
            <Typography ml={1} fontSize={14} color="#d1d4d7">
              Dashboard
            </Typography>
          </Box>
        </Pressable>

        <Pressable
          backgroundColor="#2a2c36"
          onPress={() => {
            navigate("/home/batteries");
          }}
        >
          <Box                           // Flex
            backgroundColor={location === "batteries" ? "#00000054" : "#2a2c36"}
            width="100%"
            p={1.5}
            className={styles.menuItems}
          >
            <BsBatteryFull
              size={20}
              color={location === "batteries" ? "#20a8d8" : "#fff"}
            />
            <Typography fontSize={14} ml={1} color="#d1d4d7">
              Batteries
            </Typography>
          </Box>
        </Pressable>

        <Pressable
          backgroundColor="#2a2c36"
          onPress={() => {
            navigate("/home/customers");
          }}
        >
          <Box                           // Flex
            backgroundColor={location === "customers" ? "#00000054" : "#2a2c36"}
            width="100%"
            p={1.5}
            className={styles.menuItems}
          >
            <BsFillPeopleFill
              size={20}
              color={location === "customers" ? "#20a8d8" : "#fff"}
            />
            <Typography fontSize={14} ml={1} color="#d1d4d7">
              Customers
            </Typography>
          </Box>
        </Pressable>
      </Box>
    </Box>
  );
};

export default Drawer;

const useStyles = makeStyles(theme => ({
  sidedrawer: {
    transform: "translateX(0%)",
    transition: "transform 0.2s ease-out",
    width: "20%",
    height: "100vh",
    background: "#2a2c36",
    position: "fixed",
    left: "0%",
    top: 60,
    zIndex: 100,
    [theme.breakpoints.down('md')]: {
      width: "100%",
    }
  },
  sidedraweropen: {
    transform: "translateX(-100%)",
    zIndex: "100",
  },
  avatar: {
    display: "flex",
    height: "30%",
    width: "100%",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#00000054",
  },
  menu: {
    display: "flex",
  },
  menuItems: {
    display: "flex",
    alignItems: "center",
    gap: 6
  }
}));
