import { FaHashtag, FaMoneyBillAlt, FaRecycle } from "react-icons/fa";
import { BsLightbulb } from "react-icons/bs";
import { MdFlashOn } from "react-icons/md";
import { ImMeter } from "react-icons/im";
import { BiPound } from "react-icons/bi";
import { TbScaleOutline } from "react-icons/tb";
export const data1 = [
  {
    id: 1,
    title: "DEVICE COUNT",
    itemname: "deviceCount",
    accessor: "deviceCount",
  },
  {
    id: 2,
    title: "STORED ENERGY",
    itemname: "storedEnergy",
    accessor: "storedEnergy",
  },
  {
    id: 3,
    title: "MAXIMUM CAPACITY",
    itemname: "capacity",
    accessor: "maxCapacity",
  },
  {
    id: 4,
    title: "STATE OF CHARGE",
    itemname: "soC",
    accessor: "stateOfCharge",
  },
  {
    id: 5,
    title: "CURRENT PRICE",
    itemname: "price",
    accessor: "currentPrice",
  },
];

export const dataAddOn1 = {
  deviceCount: {
    icon: <FaHashtag size={45} color="#d1d4d7" />,
    text: " devices",
    borderColor: "#63c2de",
  },
  storedEnergy: {
    icon: <BsLightbulb size={45} color="#d1d4d7" />,
    text: "kWh",
    borderColor: "#4dbd74",
  },
  maxCapacity: {
    icon: <MdFlashOn size={45} color="#d1d4d7" />,
    text: "kWh",
    borderColor: "#f8cb00",
  },
  stateOfCharge: {
    icon: <ImMeter size={45} color="#d1d4d7" />,
    text: "%",
    borderColor: "#20a8d8",
  },
  currentPrice: {
    icon: <FaMoneyBillAlt size={45} color="#d1d4d7" />,
    text: "£/MWh",
    borderColor: "#f86c6b",
  },
};

export const data2 = [
  {
    id: 1,
    title: "MONEY MADE",
    itemname: "moneyMade",
    accessor: "moneyMade",
  },
  {
    id: 2,
    title: "CARBON SAVED",
    itemname: "carbonSaved",
    accessor: "carbonSaved",
  },
  {
    id: 3,
    title: "SYSTEM EFFICIENCY",
    itemname: "systemEfficiency",
    accessor: "systemEfficiency",
  },
];

export const dataAddOn2 = {
  moneyMade: {
    icon: <BiPound size={50} color="#d1d4d7" />,
    text: "p",
    borderColor: "#63c2de",
  },
  carbonSaved: {
    icon: <FaRecycle size={50} color="#d1d4d7" />,
    text: "g CO2",
    borderColor: "#4dbd74",
  },
  systemEfficiency: {
    icon: <TbScaleOutline size={55} color="#d1d4d7" />,
    text: "%",
    borderColor: "#f86c6b",
  },
};

export const buttonData = [
  { id: "1", name: "Today" },
  { id: "2", name: "Yesterday" },
  { id: "3", name: "This Week" },
  { id: "4", name: "Last Week" },
  { id: "5", name: "This Month" },
  { id: "6", name: "Last Month" },
  { id: "7", name: "This Year" },
];
