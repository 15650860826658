import React, { useContext, useEffect, useState } from "react";
import { Context as AppContext } from "../context/AppContext";

import {
  buttonData,
  data1,
  data2,
  dataAddOn1,
  dataAddOn2,
} from "../data/homeData";
import Timeline from "../components/timeline";
import Card from "../components/card";
import Container from "../components/container";
import Drawer from "../components/drawer";

import {
  getPortfolioPlannedImports,
  getLatestMarketPrice,
  getLatestPortfolioChargeState,
  getMarketPrices,
  getPortfolioCarbonFlowsSum,
  getPortfolioCashFlows,
  getPortfolioCashFlowsSum,
  getPortfolioEnergyFlows,
  getPortfolioCarbonFlows,
  getPortfolioChargeStates,
  getImportPrices,
  getExportPrices,
} from "../Api/homeApi";
import moment from "moment";
import CustomEChart from "../components/Echarts";
import { AscDateArray, convertJsonToCsv } from "../utils/inverter";
import { BsDownload } from "react-icons/bs"
import { Box, Button, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import MaintenanceAlert from "../components/MaintenanceAlert";

let chartLabels = [];
let importGraphData = []
let exportGraphData = []

let marketPriceandCashSavingsChart = {
  x: [],
  charts: []
}

let importExportChart = {
  x: [],
  charts: []
}

let accumulatedImbalanceChart = {
  x: [],
  charts: []
}

let stateOfChargeChart = {
  x: [],
  charts: []
}

let carbonSavingsChart = {
  x: [],
  charts: []
}

let topComponentData = {};

let carbonSaved = 0.0;
let moneyMade = 0.0;
let systemEfficiency = 0.0;

let bottomComponentData = {};

function Home() {
  const styles = useStyles()
  const [containerClass, setContainerClass] = useState("");
  const [drawerContainerClass, setDrawerContainerClass] = useState("");
  const [portfolioTopData, setPortfolioTopData] = useState({});
  const [portfolioBottomData, setPortfolioDataBottomData] = useState({});
  const [graphLoading, setGraphLoading] = useState(false)
  const [from, setFrom] = useState(moment().utc().startOf("day"));
  const [to, setTo] = useState(moment().utc());
  const [chunks, setChunks] = useState(1);
  const [titleAddOn, setTitleAddon] = useState("- Today");
  const [format, setFormat] = useState("HH");
  const [timeline, setTimeline] = useState("Today")

  const { state, show } = useContext(AppContext);

  //#region functions
  useEffect(() => {
    if (show) {
      setContainerClass(null);
      setDrawerContainerClass(null);
    } else {
      setContainerClass(styles.containeropen);
      setDrawerContainerClass(styles.drawercontaineropen);
    }
  }, [show]);


  useEffect(() => {
    const getdata = async () => {
      let latestPortfolioChargeState = await getLatestPortfolioChargeState(
        state.token
      );
      let latestPrice = await getLatestMarketPrice(state.token);
      topComponentData = {
        ...latestPortfolioChargeState,
        ...latestPrice,
      };
      topComponentData = {
        ...topComponentData,
        storedEnergy:
          topComponentData.storedEnergy &&
          Math.round(topComponentData.storedEnergy),
        soC: topComponentData.soC && Math.round(topComponentData.soC * 100),
        price: topComponentData.price && Math.round(topComponentData.price),
      };
    };
    if (state.token) {
      getdata().then(() => {
        setPortfolioTopData(topComponentData);
      });
      getfromTo(from, to, format, titleAddOn, chunks, timeline);
      initGraphs(from, to, chunks, state.token);
    }
  }, [state.token]);

  const getfromTo = async (from, to, format, titleAddon, chunks, tag) => {
    setFrom(from);
    setTo(to);
    setChunks(chunks);
    setTitleAddon(titleAddon);
    setFormat(format);
    setTimeline(tag)
    moneyMade = await getPortfolioCashFlowsSum(from, to, state.token);
    carbonSaved = await getPortfolioCarbonFlowsSum(from, to, state.token);
    await initGraphs(from, to, chunks, state.token);

    bottomComponentData = {
      moneyMade: moneyMade && Math.round(moneyMade * 100) / 100,
      carbonSaved: carbonSaved && Math.round(carbonSaved * 100) / 100,
      systemEfficiency: systemEfficiency && Math.round(systemEfficiency * 100),
    };

    setPortfolioDataBottomData(bottomComponentData);
  };

  const initGraphs = async (from, to, chunks, token) => {
    setGraphLoading(true);
    let cashSavingsGraphData = await getPortfolioCashFlows(
      from,
      to,
      chunks,
      token
    );

    let marketPricesGraphData = await getMarketPrices(from, to, chunks, token);


    importGraphData = await getImportPrices(from, to, token)
    exportGraphData = await getExportPrices(from, to, token)

    let netImportChartData = await getPortfolioEnergyFlows(
      from,
      to,
      chunks,
      token
    );

    let plannedImportsChartData = await getPortfolioPlannedImports(
      from,
      to,
      chunks,
      token
    );

    let carbonSavingsChartData = await getPortfolioCarbonFlows(
      from,
      to,
      chunks,
      token
    );

    let portfolioChargeStates = await getPortfolioChargeStates(
      from,
      to,
      chunks,
      token
    );

    let cashSavingsGraphDataArray = cashSavingsGraphData?.map(
      (item) => item.cashFlow
    );

    let marketPricesGraphDataArray = marketPricesGraphData?.map(
      (item) => item.price
    );

    importGraphData = AscDateArray(importGraphData, "periodFrom")
    exportGraphData = AscDateArray(exportGraphData, "periodFrom")
    const importArray = importGraphData?.map(item => item.importPrice)
    const exportArray = exportGraphData?.map(item => item.exportPrice)


    const xImpExp = importGraphData?.map(item => item.periodFrom)

    let netImportChartDataArray = netImportChartData?.map(
      (item) => item.netImport
    );

    let plannedImportsChartDataArray = plannedImportsChartData?.map(
      (item) => item.netImport
    );

    let accumulatedDifferenceArray = plannedImportsChartDataArray?.map(
      (item, index) => item - netImportChartDataArray[index]
    );

    let carbonSavingsChartDataArray = carbonSavingsChartData?.map(
      (item) => item.carbonFlow
    );

    let portfolioChargeStatesArray = portfolioChargeStates?.map(
      (item) => item.soC
    );

    chartLabels = marketPricesGraphData?.map((item) => item.periodTo)

    marketPriceandCashSavingsChart = {
      x: chartLabels,
      charts: [
        {
          type: "line",
          name: "Cash Savings",
          y: marketPricesGraphDataArray,
        },
        {
          type: "bar",
          name: "Market Price",
          y: cashSavingsGraphDataArray
        },
      ],
    };

    importExportChart = {
      x: xImpExp,
      charts: [
        {
          type: "line",
          y: importArray,
          name: "Import Prices"
        },
        {
          type: "line",
          y: exportArray,
          name: "Export Prices"
        },
      ]
    }

    accumulatedImbalanceChart = {
      x: chartLabels,
      charts: [
        {
          type: "line",
          name: "Accumulated Difference",
          y: accumulatedDifferenceArray
        },
        {
          type: "bar",
          name: "Net Import",
          y: netImportChartDataArray,
        },
        {
          type: "bar",
          name: "Planned Imports",
          y: plannedImportsChartDataArray
        },
      ],
    };

    carbonSavingsChart = {
      x: chartLabels,
      charts: [
        {
          type: "bar",
          name: "Carbon Savings",
          y: carbonSavingsChartDataArray,
        },
      ],
    };

    stateOfChargeChart = {
      x: chartLabels,
      charts: [
        {
          type: "line",
          name: "State of Charge",
          y: portfolioChargeStatesArray
        },
      ],
    };

    setGraphLoading(false);
  };

  const today = moment().format("yyyy-MM-DD")
  const getExportPricesCsv = () => {
    let name = "Import-Prices" + "-" + today
    convertJsonToCsv(exportGraphData, name)
  }
  const getImportPricesCsv = () => {
    let name = "Export-Prices" + "-" + today
    convertJsonToCsv(importGraphData, name)
  }

  //#endregion

  const PricesIcon = () => (
    <Box className={styles.csvButtons} >
      <Button
        variant={'outlined'}
        disabled={graphLoading}
        size="small"
        startIcon={
          <BsDownload
            size={14}
          />
        }
        onClick={getImportPricesCsv}>
        Import

      </Button>
      <Button
        variant={'outlined'}
        disabled={graphLoading}
        size="small"
        startIcon={
          <BsDownload
            size={14}
          />
        }
        onClick={getExportPricesCsv}>
        Export

      </Button>
    </Box>
  )

  return (
    <>
      <Stack direction="row">
        <Box
          height="100%"
          className={clsx(styles.drawercontainer, drawerContainerClass)}
        >
          <Drawer show={show} location="portfolio" />
        </Box>
        <Box className={clsx(styles.container, containerClass)}>
          <MaintenanceAlert />
          <Card
            labels={data1}
            dataAddOn={dataAddOn1}
            portfolioData={portfolioTopData}
          />
          <Timeline defaultSetRange={'Today'} data={buttonData} getfromTo={getfromTo} />
          <Card
            labels={data2}
            dataAddOn={dataAddOn2}
            portfolioData={portfolioBottomData}
          />

          <Container title={`Market Price and Cash savings [p] ${titleAddOn}`}>
            <CustomEChart
              loading={graphLoading}
              timeline={timeline}
              data={marketPriceandCashSavingsChart}
            />
          </Container>


          <Container title={`Import-Export Prices ${titleAddOn}`} sideComponent={<PricesIcon />} >
            <CustomEChart
              loading={graphLoading}
              timeline={timeline}
              data={importExportChart}
            />
          </Container>

          <Container title={`Accumulated Imbalance ${titleAddOn}`}>
            <CustomEChart
              loading={graphLoading}
              timeline={timeline}
              data={accumulatedImbalanceChart}
            />
          </Container>

          <Container title={`State of Charge ${titleAddOn}`}>
            <CustomEChart
              loading={graphLoading}
              timeline={timeline}
              data={stateOfChargeChart}
            />
          </Container>

          <Container title={`Carbon savings [g CO2] ${titleAddOn}`}>
            <CustomEChart
              loading={graphLoading}
              timeline={timeline}
              data={carbonSavingsChart}
            />
          </Container>

        </Box>
      </Stack>
    </>
  );
}

export default Home;

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 30,
    paddingTop: 80,
    width: "80%",
    padding: 40,
    transition: "width 0.3s ease-out",
  },
  containeropen: {
    width: "100%",
  },
  drawercontainer: {
    width: "20%",
    transition: "width 0.1s ease-out",
  },
  drawercontaineropen: {
    width: "0%",
    zIndex: "100",
  },
  csvButtons: {
    display: "flex",
    flexDirection: "row",
    gap: 10
  },
}));
