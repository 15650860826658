import { combineReducers } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';
import { customerReducer } from '../slices/customerSlice';
import { batteriesReducer } from '../slices/batteriesSlice';

const rootReducer = combineReducers({
    form: formReducer,
    customers: customerReducer,
    batteries: batteriesReducer
});

export default rootReducer;
