import ReactECharts from 'echarts-for-react';
import moment from 'moment';
import GaugeComponent from 'react-gauge-component';

let hoursLabel = []
for (let i = 0; i <= 23; i++) {
    let hour1 = String(i).padStart(2, "0") + ":00"
    let hour2 = String(i).padStart(2, "0") + ":30"
    hoursLabel.push(hour1)
    hoursLabel.push(hour2)
}

const colors = ["#22CFCF", "#FF6384", "#4DBD74", "purple"]

const getTimeFormat = (value, timeline) => {
    let tag = timeline ?? "Today"
    let format = "HH:mm";
    switch (tag) {
        case "Today":
        case "Yesterday":
            return value
        // case "This Week":
        // case "Last Week":
        //     format = "dddd"
        //     break;
        // case "This Month":
        // case "Last Month":
        //     format = "yyyy-MM-DD"
        //     break;
        default:
            format = "yyyy-MM-DD"
    }
    return moment(value).format(format)
}

export const CustomEChart2 = ({ data, loading, timeline, min, max }) => {
    // console.log(`============== ${data.name} =============`)
    const dataset = data.datasets ?? []
    let newDataSet = []
    if (timeline === "Today" || timeline === "Yesterday") {
        const currentDate = timeline === "Yesterday" ? moment().subtract(1, 'days') : moment()
        for (let i = 0; i <= 23; i++) {
            let date;
            let day = dataset[0] && dataset[0][0] ? moment(dataset[0][0]).format("yyyy-MM-DD") : currentDate.format("yyyy-MM-DD")
            // console.log("day ==>", day)
            let hour = `${String(i).padStart(2, "0")}:00`
            date = `${day} ${hour}`
            // console.log("date ==>", date)
            let found = dataset.find(dataArr => dataArr[0] === date)
            // console.log("found ==>", found)
            const pushToDataset = (dt) => {
                let newItem = []
                newItem.push(dt)
                data.series.map((_, index) => {
                    if (found) {
                        newItem.push(found[index + 1])
                    }
                    else
                        newItem.push(0)
                })
                // console.log("newItem ==>", newItem)
                newDataSet.push(newItem)
            }
            pushToDataset(date);
            hour = `${String(i).padStart(2, "0")}:30`
            date = `${day} ${hour}`
            found = dataset.find(dataArr => dataArr[0] === date)
            // console.log("found ==>", found)
            // console.log("date ==>", date)
            pushToDataset(date);
        }
    } else {
        newDataSet = [...dataset]
    }

    let options = {
        grid: { top: 25, right: 8, bottom: 24, left: 46, show: true },
        animation: true,
        animationDuration: 200,
        dataZoom: {
            type: "inside",
            start: 0,
            end: 100,
        },
        series: [],
        legend: {
            data: data.series?.map((item) => item.name)
        },
        tooltip: {
            trigger: 'axis',
            show: true,
            formatter: (params) => {
                // console.log("params ==>", params)
                let date = params[0].axisValueLabel
                let vals = ""
                params.map((item, index) => {
                    vals += `<tr> <td> ${item.marker} `;
                    // console.log("item.data[index + 1] ==>", item.data[index + 1])
                    const dataValue = item.data[index + 1]?.toFixed(4).replace(/\.?0+$/, '')
                    if (Array.isArray(data.yTTFormat) && data.yTTFormat[index] && typeof data.yTTFormat[index] === "string") {
                        vals += item.seriesName + "</td> <td>&nbsp;</td>  <td><b>" + data.yTTFormat[index].replace("{value}", dataValue) + "</b></td>"
                    } else {
                        vals += item.seriesName + "</td> <td>&nbsp;</td>  <td><b>" + dataValue + "</b></td>"
                    }
                    vals += "</tr>"
                })
                return `${date} <table>${vals}</table>`;
            },
        },
        // tooltip: {
        //     show: true,
        //     trigger: "axis",
        //     confine: true,
        // },
        xAxis: [
            {
                type: "time",
                scale: true,
                nameLocation: "middle",
                nameTextStyle: {
                    fontFamily: "Montserrat, sans-serif",
                    fontSize: 10,
                    fontWeight: "bold",
                },
                nameGap: 25,
            },
        ],
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: data.yAxisFormat ?? '{value}',
            }
        },
        dataset: {
            source: newDataSet,
            dimensions: [
                {
                    name: "datetime",
                    fieldType: "time",
                },
            ],
        },
        qcMode: "history",
    };

    data.series?.map((chart, index) => {
        options.dataset.dimensions.push({
            name: chart.name,
            type: chart.fieldType
        })
        switch (chart.type) {
            case "bar":
                options.series.push({
                    type: "bar",
                    name: chart.name,
                    encode: {
                        x: 0,
                        y: index + 1,
                    },
                    yAxisIndex: 0,
                    smooth: true,
                    // symbol: "none",
                    itemStyle: {
                        color: colors[index],   // Customize bar color
                    },
                },)
                break
            case "line":
                options.series.push({
                    type: "line",
                    name: chart.name,
                    encode: {
                        x: 0,
                        y: index + 1,
                    },
                    // yAxisIndex: 0,
                    smooth: true,
                    // symbol: "none",
                    itemStyle: {
                        color: colors[index],
                    },
                })
                break
        }
    })

    if (min) {
        options.yAxis.min = min
    }
    if (max) {
        options.yAxis.max = max
    }

    return (
        <ReactECharts
            option={options}
            showLoading={loading}
        />
    )
}

const CustomEChart = ({ data, loading, timeline, min, max }) => {
    let xLabels = []
    if (timeline === "Today" || timeline === "Yesterday") {
        const date = {
            Today: moment().format("yyyy-MM-DD"),
            Yesterday: moment().subtract(1, 'days').format("yyyy-MM-DD")
        }
        xLabels = hoursLabel.map(h => {
            return moment(`${date[timeline]} ${h}`).format("HH:mm")
        })
    }
    else xLabels = data.x
    let options = {
        grid: { top: 25, right: 8, bottom: 24, left: 46, show: true },
        xAxis: {
            type: 'category',
            data: xLabels,
            axisLabel: {
                formatter: (value) => {
                    return getTimeFormat(value, timeline)
                }
            }
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: data.yAxisFormat ?? '{value}',
            }
        },
        legend: {
            data: data.charts.map(item => item.name)
        },
        series: [{
            type: "line",
            data: []
        }],
        // tooltip: {
        //     trigger: 'axis',
        //     show: true,
        //     formatter: (params) => {
        //         // console.log("params ==>", params)
        //         let date = params[0].axisValueLabel
        //         let vals = "<table>"
        //         params.map((item, index) => {
        //             vals += `<tr> <td> ${item.marker} `;
        //             const dataValue = item.data[index + 1].toFixed(4).replace(/\.?0+$/, '')
        //             if (Array.isArray(data.yTTFormat) && data.yTTFormat[index] && typeof data.yTTFormat[index] === "string") {
        //                 vals += item.seriesName + "</td> <td>&nbsp;</td>  <td><b>" + data.yTTFormat[index].replace("{value}", dataValue) + "</b></td>"
        //             } else {
        //                 vals += item.seriesName + "</td> <td>&nbsp;</td>  <td><b>" + dataValue + "</b></td>"
        //             }
        //             vals += "</tr>"
        //         })
        //         return `${date}${vals} </table>`;
        //     },
        // },
        tooltip: {
            trigger: 'axis',
        },
        dataZoom: [
            {
                type: 'inside',
                start: 0,
                end: 100,
            }
        ]
    };

    if (min) {
        options.yAxis.min = min
    }
    if (max) {
        options.yAxis.max = max
    }

    data.charts.map((chart, index) => {
        switch (chart.type) {
            case "bar":
                options.series.push(
                    {
                        data: chart.y,
                        type: 'bar',
                        smooth: true,
                        itemStyle: {

                            color: colors[index],
                        },
                        name: chart.name
                    })
                break
            case "line":
                options.series.push(
                    {
                        data: chart.y,
                        type: 'line',
                        smooth: true,
                        itemStyle: {
                            color: colors[index],
                        },
                        name: chart.name
                    })
                break
        }
    })

    return <ReactECharts option={options} showLoading={loading} />
};

export default CustomEChart;

export const GaugeEChart = ({ data, color, arc, loading }) => {
    // const option = {
    //     color: color,
    //     // tooltip: {},
    //     series: [
    //         {
    //             type: 'pie',
    //             radius: arc?.radius ?? ['70%', '100%'],
    //             center: arc?.center ?? ['50%', '70%'],
    //             // adjust the start and end angle
    //             startAngle: 190,
    //             endAngle: 350,
    //             data: data.map(val => { return { value: val } }),
    //             label: {
    //                 show: false,
    //                 // position: 'center'
    //             },
    //             labelLine: {
    //                 show: false
    //             },
    //         }
    //     ],
    //     emphasis: {
    //         itemStyle: {
    //             shadowBlur: 5,
    //             shadowOffsetX: 0,
    //             shadowColor: 'rgba(0, 0, 0, 0.5)'
    //         }
    //     }
    // };

    const option = {
        series: [
            {
                type: 'gauge',
                center: ['50%', '60%'],
                // radius: arc?.radius ?? ['70%', '100%'],
                startAngle: 200,
                endAngle: -20,
                min: 0,
                max: 100,
                splitNumber: 10,
                itemStyle: {
                    color: '#91e3ff'
                },
                progress: {
                    show: true,
                    width: 20
                },
                pointer: {
                    show: false
                },
                axisLine: {
                    lineStyle: {
                        width: 20
                    }
                },
                axisTick: {
                    distance: -28,
                    splitNumber: 5,
                    lineStyle: {
                        width: 1,
                        color: '#999'
                    }
                },
                splitLine: {
                    distance: -35,
                    length: 14,
                    lineStyle: {
                        width: 2,
                        color: '#999'
                    }
                },
                axisLabel: {
                    distance: -15,
                    color: '#999',
                    fontSize: 10
                },
                anchor: {
                    show: false
                },
                title: {
                    show: false
                },
                detail: {
                    valueAnimation: true,
                    width: '100%',
                    lineHeight: 40,
                    borderRadius: 8,
                    offsetCenter: [0, '-15%'],
                    fontSize: 25,
                    fontWeight: 'bolder',
                    formatter: '{value}%',
                    color: 'inherit'
                },
                data: [
                    {
                        value: data
                    }
                ]
            },
            {
                type: 'gauge',
                center: ['50%', '60%'],
                startAngle: 200,
                endAngle: -20,
                min: 0,
                max: 100,
                itemStyle: {
                    color: '#00bfff'
                },
                progress: {
                    show: true,
                    width: 8
                },
                pointer: {
                    show: false
                },
                axisLine: {
                    show: false
                },
                axisTick: {
                    show: false
                },
                splitLine: {
                    show: false
                },
                axisLabel: {
                    show: false
                },
                detail: {
                    show: false
                },
                data: [
                    {
                        value: data
                    }
                ]
            }
        ]
    };

    return <ReactECharts option={option} showLoading={loading} style={{ height: 250, transform: "scale(1.2)" }} />
}

export const GaugeEChart2 = ({ value }) => {
    return (
        <GaugeComponent
            arc={{
                subArcs: [
                    {
                        limit: 50,
                        color: '#f7725d',
                        showTick: true
                    },
                    {
                        limit: 70,
                        color: '#f7d954',
                        showTick: true
                    },
                    {
                        limit: 100,
                        color: '#8bef67',
                        showTick: true
                    },
                ],
                width: 0.20
            }}
            labels={{
                valueLabel: {
                    style: { fontSize: "35px", fontWeight: "bold", textShadow: "none", fill: "#6b7280", transform: "translateY(-10%)" }
                },
                tickLabels: {
                    defaultTickValueConfig: {
                        style: { fontSize: "12px", fontWeight: "500", fill: "#999999", textShadow: "none" }
                    },
                    defaultTickLineConfig: {
                        width: 2, color: "#999999", length: 14
                    }
                }
            }}
            value={value}
            style={{ width: 380, alignSelf: "center" }}
        />
    )
}