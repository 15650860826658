// import { createTheme, ThemeProvider } from "@mui/material";
import { Provider as AppProvider } from "./context/AppContext";
import Navigation from "./navigation.js";
import { NativeBaseProvider, Box } from "native-base";
import Header from "./components/header.js";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";
import { Provider as ReduxProvider } from 'react-redux';
import store from "./store";

function App() {
  return (
    <ReduxProvider store={store}>
      <AppProvider>
        <ThemeProvider theme={theme}>
          <NativeBaseProvider>
            <Header />
            <Navigation />
          </NativeBaseProvider>
        </ThemeProvider>
      </AppProvider>
    </ReduxProvider>
  );
}

export default App;
