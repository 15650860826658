import axios from "axios";
import * as moment from "moment";

// let auth_url =
//   "https://chargesyncadminapiidentity.azurewebsites.net/connect/token";
// let url = "https://chargesyncadminwebapi.azurewebsites.net/api";

// let auth_url =
//   "https://dev-chargesyncadminidentity.azurewebsites.net/connect/token";
// let url = "https://dev-chargesyncadminapi.azurewebsites.net/api";


let auth_url = "https://identity.chargesync.com/connect/token";
let url = "https://webapi.chargesync.com/api";

export const loginUser = async (name, password) => {
  const headers = {
    'Content-Type': "application/x-www-form-urlencoded",
    "Access-Control-Allow-Origin": "*" // Example of adding an Authorization header
  };
  var bodyFormData = new URLSearchParams();
  bodyFormData.append("grant_type", "password");
  bodyFormData.append("client_id", "angularclient");
  bodyFormData.append("client_secret", "secret");

  bodyFormData.append("username", name);
  bodyFormData.append("password", password);



  return axios.post(auth_url, bodyFormData.toString(), { headers })
    .then((res) => {
      console.log(res);
      return res.data;
    })
    .catch((error) => {
      console.log(error);
    });
};

const claimsData = {
  deviceId: null,
  firstName: "Matthew",
  secondName: "Hunt",
  userId: "3914b300-3259-4276-af46-48e5ec6668cd",
  isAdmin: true,
  claims: [
    {
      type: "nbf",
      value: "1685432459",
    },
    {
      type: "exp",
      value: "1685434259",
    },
    {
      type: "iss",
      value: "https://chargesyncadminapiidentity.azurewebsites.net",
    },
    {
      type: "aud",
      value: "https://chargesyncadminapiidentity.azurewebsites.net/resources",
    },
    {
      type: "aud",
      value: "chargeSyncAdminApi",
    },
    {
      type: "client_id",
      value: "angularclient",
    },
    {
      type: "sub",
      value: "3914b300-3259-4276-af46-48e5ec6668cd",
    },
    {
      type: "auth_time",
      value: "1685432459",
    },
    {
      type: "idp",
      value: "local",
    },
    {
      type: "role",
      value: "admin",
    },
    {
      type: "scope",
      value: "openid",
    },
    {
      type: "scope",
      value: "profile",
    },
    {
      type: "scope",
      value: "roles",
    },
    {
      type: "scope",
      value: "chargeSyncAdminApi",
    },
    {
      type: "amr",
      value: "pwd",
    },
  ],
};

export const getClaims = async (token, expires_in, username) => {
  if (token) {
    let expireIn = moment.utc().add(expires_in, "s"); //     let expireIn = moment.utc().add(res.expires_in, "s");

    // let headers = new Headers({ Authorization: "Bearer " + token });
    console.log("token>>>>", token);
    // let options = { headers: headers };

    let res = await axios({
      method: "get",
      url: url + "/User/Claims",
      headers: {
        Authorization: "Bearer " + token,
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then((res) => res.data)
      .catch((error) => {
        console.log(error);
      });

    let user = res; // let user = res.data;
    let now = new Date();
    let currentUser = {
      Username: username,
      Token: token,
      ExpireIn: expireIn,
      Claims: [],
      UserId: "",
      DeviceId: "",
      FirstName: "",
      SecondName: "",
      UserIsAdmin: false,
      UserRole: "",
    };

    currentUser.Claims = user.claims;
    const findClaimValue = (key) => {
      return user.claims?.find(item => item.type === `http://schemas.xmlsoap.org/ws/2005/05/identity/claims/${key}`)?.value
    }

    let claim = currentUser.Claims.find(function (claim) {
      return claim.type == "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
    });
    currentUser.UserRole = claim.value;
    currentUser.UserIsAdmin = user.isAdmin;
    currentUser.DeviceId = user.deviceId;
    currentUser.FirstName = findClaimValue("givenname");
    currentUser.SecondName = findClaimValue("surname");
    currentUser.UserId = user.userId;

    if (currentUser.FirstName && currentUser.SecondName) {
      currentUser.Username = `${currentUser.FirstName} ${currentUser.SecondName}`
    }

    // store username and jwt token in local storage to keep user logged in between page refreshes
    sessionStorage.setItem("currentUser", JSON.stringify(currentUser));

    // return true to indicate successful login
    return currentUser;
  }
};
