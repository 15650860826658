import React from "react";
import {
  Typography,
  Box,
  useMediaQuery,
  Card,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const Cards = ({ labels, dataAddOn, portfolioData }) => {
  const classes = useStyles()
  const md = useMediaQuery(theme => theme.breakpoints.down("md"))
  return (
    <Card className={classes.root}>
      {labels.map((item) => (
        <Box
          key={item.id}
          style={{ width: md ? "100%" : `${100 / labels.length}%` }}
          className={classes.card}
        >
          <Box className={classes.flexBox} style={{ justifyContent: "flex-end" }}>
            {dataAddOn[item.accessor].icon}
          </Box>
          <Box className={classes.content}>
            <Box
              style={{ flexGrow: 1, flex: 1 }}
              width={md ? "85%" : "100%"}
            >
              <Typography variant="h6" fontWeight="600">
                {portfolioData[item.itemname]}
                {dataAddOn[item.accessor].text}
              </Typography>
              <Typography fontWeight="600" color="#d1d4d7">
                {item.title}
              </Typography>
            </Box>
            <Box
              style={{ backgroundColor: dataAddOn[item.accessor].borderColor }}
              className={classes.bottomAccent}
            ></Box>
          </Box>
        </Box>
      ))}
    </Card>
  );
};

export default Cards;

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    // alignItems: "center",
    width: "100%",
    padding: "0px",
  },
  card: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    minHeight: 180,
    gap: 20,
    padding: "25px 30px",
    width: "100%",
    overflow: "hidden",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
    [theme.breakpoints.down("md")]: {
      width: "100%"
    },
  },
  flexBox: {
    display: "flex",
    justifyContent: "space-between"
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  bottomAccent: {
    width: "100%",
    height: 5,
    justifySelf: "flex-end"
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  }
}))
