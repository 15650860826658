import { AiFillCalendar, AiFillThunderbolt, AiOutlineBarcode } from "react-icons/ai";
import { BsLightbulb } from "react-icons/bs";
import { VscPulse } from "react-icons/vsc";

export const data1 = [
    {
        id: 1,
        title: "DEVICE ID",
        itemname: "deviceId",
        accessor: "deviceId",
        format: (value) => {
            if (value)
                return String(value).toUpperCase()
            return value
        }
    },
    {
        id: 2,
        title: "LAST TIME ACTIVITY",
        itemname: "lastActivityTime",
        accessor: "lastActivityTime",
    },
    {
        id: 3,
        title: "CURRENT BATTERY MODE",
        itemname: "status",
        accessor: "status",
        color: (value) => {
            switch (value) {
                case "CHARGING":
                    return "green"
                case "DISCHARGING":
                case "INACTIVE":
                    return "red"
                default:
                    return "#000"
            }
        }
    }
];

const iconSize = 60
export const dataAddOn1 = {
    deviceId: {
        icon: <AiOutlineBarcode size={iconSize} color="#d1d4d7" />,
        text: "",
        borderColor: "#63c2de",
    },
    lastActivityTime: {
        icon: <AiFillCalendar size={iconSize} color="#d1d4d7" />,
        text: "",
        borderColor: "#4dbd74",
    },
    status: {
        icon: <VscPulse size={iconSize} color="#d1d4d7" />,
        text: "",
        borderColor: "#20a8d8",
    }
};

export const buttonData = [
    { id: "1", name: "Today" },
    { id: "2", name: "Yesterday" },
    { id: "3", name: "This Week" },
    { id: "4", name: "Last Week" },
    { id: "5", name: "This Month" },
    { id: "6", name: "Last Month" },
    { id: "7", name: "This Year" },
];