import React, { useState } from "react";
import Container from "./container";
import { BsCalendar3 } from "react-icons/bs";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import { Button, InputBase, Paper, useTheme, Box, Menu, MenuItem, MenuList, Typography, Tooltip, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { InfoOutlined, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
const moment = extendMoment(originalMoment);

const Timeline = ({ data, getfromTo, defaultSetRange }) => {
  const [value, onChange] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [toggleCalendar, setToggleCalendar] = useState(false);
  const [selectedButton, setSelectedButton] = useState(defaultSetRange || '');
  const theme = useTheme()
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, val) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (name) => {
    setDate(name)
    handleClose()
  }

  const setDate = (tag, fromDate, toDate) => {
    setSelectedButton(tag);
    var from;
    var to;
    var format = "";
    var titleAddon = "";
    var chunks = 0;
    var labels = [];
    // eslint-disable-next-line default-case

    switch (tag) {
      case "Today":
        // today
        from = moment().utc().startOf("day");
        to = moment().utc();
        format = "HH";
        chunks = 1;
        titleAddon = " - Today";
        labels = [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "23",
          "24",
        ];
        break;
      case "Yesterday":
        // yesterday
        from = moment().utc().add(-1, "d").startOf("day");
        to = moment().utc().add(-1, "d").endOf("day");
        format = "HH";
        chunks = 1;
        titleAddon = " - Yesterday " + from.format("dddd");
        labels = [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "23",
          "24",
        ];
        break;
      case "This Week":
        // this week
        var dayOfweek = moment().utc().weekday();
        from = moment()
          .utc()
          .add(-dayOfweek + 1, "d")
          .startOf("day");
        to = from.clone().add(7, "d").utc();
        format = "dddd";
        chunks = 2;
        titleAddon =
          " - This week " +
          "(" +
          from.format("D MMM") +
          "-" +
          to.format("D MMM") +
          ")";
        labels = [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ];
        break;
      case "Last Week":
        // last week
        var dayOfweek = moment().utc().weekday();
        from = moment()
          .utc()
          .add(-(dayOfweek + 7) + 1, "d")
          .startOf("day");
        to = moment()
          .utc()
          .add(-dayOfweek + 1, "d")
          .startOf("day");
        format = "dddd";
        chunks = 2;
        titleAddon =
          " - Last week " +
          "(" +
          from.format("D MMM") +
          "-" +
          to.format("D MMM") +
          ")";
        labels = [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ];
        break;
      case "This Month":
        // this month
        var dayOfMonth = moment().utc().date();
        from = moment()
          .utc()
          .add(-dayOfMonth + 1, "d")
          .startOf("day");
        //var m = moment().utc().month(moment().utc().month());
        var daysInMonth = moment().utc().daysInMonth();
        to = from.clone().add(daysInMonth, "d");
        format = "D";
        chunks = 2;
        titleAddon =
          " - This month " +
          "(" +
          from.format("D MMM") +
          "-" +
          to.format("D MMM") +
          ")";
        labels = [];
        break;
      case "Last Month":
        // last month
        var dayOfMonth = moment().utc().date();
        //var month = moment().utc().month();
        var lastMonth = moment().utc().add(-1, "month");
        var lastMonthDays = lastMonth.daysInMonth();
        from = moment()
          .utc()
          .add(-(dayOfMonth + lastMonthDays) + 1, "d")
          .startOf("day");
        to = from.clone().add(lastMonthDays, "d");
        format = "D";
        chunks = 2;
        titleAddon =
          " - Last month " +
          "(" +
          from.format("D MMM") +
          "-" +
          to.format("D MMM") +
          ")";
        labels = [];
        break;
      case "This Year":
        // this year
        var dayThisYear = moment().utc().dayOfYear();
        from = moment()
          .utc()
          .add(-dayThisYear + 1, "d")
          .startOf("day");
        to = moment().utc();
        format = "MMMM";
        chunks = 3;
        titleAddon = " - This Year " + from.format("YYYY");
        labels = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        break;
      case "Custom":
        // custom
        from = fromDate;
        to = toDate;
        format = "HH";
        chunks = 1;
        titleAddon = ` - Custom (${from.format("D MMM")} - ${to.format("D MMM")})`;
        labels = [];
        break;
    }

    getfromTo(from, to, format, titleAddon, chunks, tag);
  };


  const onSelect = (value) => {
    // console.log("value", value)
    onChange(value);
    setDate("Custom", value.start, value.end);
    setToggleCalendar(false)
  };

  return (
    <div style={{ positon: "relative" }}>
      <Container title="Timeline" style={{ child: { gap: 6, } }} >

        <Paper className={classes.paperBase} style={{ width: 300 }} >
          <InputBase
            disabled
            placeholder={moment(new Date()).format("YYYY-MM-DD")}
            className={classes.input}
            value={
              `${moment(value.start).format("YYYY-MM-DD")} - ${moment(value.end).format("YYYY-MM-DD")}`
            }
          // onChange={(e) => setName(e.target.value)}
          />
          <Box className={classes.inpIcon} onClick={() => setToggleCalendar(!toggleCalendar)}>
            {/* <IconButton > */}
            <BsCalendar3
              size={18}
              color="#000"
            />
            {/* </IconButton> */}
          </Box>
        </Paper>

        <Stack direction="row" style={{ color: "GrayText", gap: 10, alignItems: "center" }}>
          <Box className={classes.timelineList}>
            <Button
              style={{ borderColor: "gray" }}
              // sx={{color: "gray"}}
              height={35}
              endIcon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              id="timeline-button"
              aria-controls={open ? 'timeline-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              {selectedButton}
            </Button>


          </Box>
          <Tooltip fontr title="The date filter applies to all charts on this dashboard simultaneously." placement="right"  >
            <InfoOutlined fontSize="medium" color="inherit" />
          </Tooltip>
        </Stack>

        <Menu
          id="timeline-menu"
          aria-labelledby="timeline-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {data.map((item) => (
            <MenuItem
              style={{ fontSize: 14 }}
              onClick={() => handleSelect(item.name)}
              disabled={selectedButton === item.name}
              value={item.name}
            >
              {item.name}
            </MenuItem>
          ))}
        </Menu>


      </Container>
      {!!toggleCalendar && (
        <div
          style={{
            backgroundColor: "white",
            position: "absolute",
            zIndex: "100",
            right: "25vw",
          }}
        >
          <DateRangePicker
            value={value}
            onSelect={onSelect}
            singleDateRange={true}
          />
        </div>
      )}
    </div>
  );
};

export default Timeline;

const useStyles = makeStyles(theme => ({
  paperBase: {
    display: "flex",
    height: 35,
    borderRadius: 8,
    border: "1px solid #d1d4d7",
    margin: 1,
    alignSelf: "center"
  },
  inpIcon: {
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 50,
    borderRight: "1px solid #d1d4d7",
    backgroundColor: "#E4E5E6"
  },
  input: {
    fontSize: "14px !important",
    flex: 1,
    padding: "0px 10px !important",
    cursor: "not-allowed"
  },
  timelineList: {
    border: "1px solid #999",
    borderRadius: 5,
    display: "flex",
    alignItems: "center"
  }
}))
