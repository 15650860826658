import React, { useState, useContext, useEffect, useCallback } from 'react'
import { Context as AppContext } from "../context/AppContext";
import { Box, Stack, Card, Typography, Button, IconButton } from "@mui/material"
import Drawer from "../components/drawer"
import Timeline from "../components/timeline"
import Container from "../components/container"
import { useParams } from "react-router-dom"
import GoogleMapReact from "google-map-react";
import { AscDateArray, convertJsonToCsv, getSocDetails } from "../utils/inverter"
import {
    buttonData,
    data1,
    dataAddOn1,
} from "../data/inverterData";
import moment from 'moment';
import { getInverter, getInverterFlow, getBatteryFlow, getInverterDailyStatus, getImportPrices, getExportPrices } from '../Api/homeApi';
import { BsDownload } from "react-icons/bs"
import CustomEChart, { GaugeEChart, GaugeEChart2 } from '../components/Echarts';
import BreadcrumbTrail from '../components/BreadcrumbsTrail';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { Download } from '@mui/icons-material';

const initInfo = {
    deviceId: "loading...",
    lastActivityTime: "loading...",
    status: "loading...",
    soC: 0,
    efficiency: 0,
    storedEnergy: 0,
    // capacity: 0
}

let batteryFlowData = []
let dailyStatusData = []
let importGraphData = []
let exportGraphData = []

let socChart = {
    x: [],
    charts: []
}

let dailyStatusChart = {
    x: [],
    charts: []
}

let importExportChart = {
    x: [],
    charts: []
}

export default () => {
    const classes = useStyles();
    const [containerClass, setContainerClass] = useState("");
    const [drawerContainerClass, setDrawerContainerClass] = useState("");
    const [topData, setTopData] = useState(initInfo);
    const [graphLoading, setGraphLoading] = useState(false)
    const [from, setFrom] = useState(moment().utc().startOf("day"));
    const [to, setTo] = useState(moment().utc());
    const [chunks, setChunks] = useState(1);
    const [titleAddOn, setTitleAddon] = useState("- Today");
    const [format, setFormat] = useState("HH");
    const [timeline, setTimeline] = useState("Today")
    const { show, state } = useContext(AppContext)
    const params = useParams()

    const getfromTo = async (from, to, format, titleAddon, chunks, tag) => {
        setFrom(from);
        setTo(to);
        setChunks(chunks);
        setTitleAddon(titleAddon);
        setFormat(format);
        setTimeline(tag)

        await initGraphs(from, to, state.token);
    };


    const initGraphs = async (from, to, token) => {
        setGraphLoading(true);
        batteryFlowData = await getBatteryFlow(params.id, from, to, token)

        dailyStatusData = await getInverterDailyStatus(params.id, from, to, token)

        importGraphData = await getImportPrices(from, to, token)
        exportGraphData = await getExportPrices(from, to, token)

        importGraphData = AscDateArray(importGraphData, "periodFrom")
        exportGraphData = AscDateArray(exportGraphData, "periodFrom")
        const importArray = importGraphData?.map(item => item.importPrice)
        const exportArray = exportGraphData?.map(item => item.exportPrice)

        const xImpExp = importGraphData?.map(item => item.periodFrom)

        batteryFlowData = AscDateArray(batteryFlowData, "startTime")

        let xDailyStatus = []
        let charge = [], discharge = [], solar = [], yImport = [], yExport = []
        dailyStatusData.map(item => {
            xDailyStatus.push(item.periodFrom)
            charge.push(item.charge)
            discharge.push(item.disCharge)
            solar.push(item.solar)
            yImport.push(item.import)
            yExport.push(item.export)
        })

        let xSoc = []
        let ySoc = []
        batteryFlowData.sort((a, b) => {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);
            return dateA - dateB;
        }).map(item => {

            xSoc.push(item.createdAt)
            ySoc.push(item.endingSoC)

        })
        socChart = {
            x: xSoc,
            yAxisFormat: "{value}%",
            yTTFormat: ["{value}%"],
            charts: [{
                type: "bar",
                y: ySoc,
                name: "SoC",
            }]
        }

        dailyStatusChart = {
            x: xDailyStatus,
            charts: [
                {
                    type: "line",
                    name: "Charge",
                    y: charge
                },
                {
                    type: "line",
                    name: "Discharge",
                    y: discharge
                },
                {
                    type: "line",
                    name: "Solar",
                    y: solar
                },
                {
                    type: "line",
                    name: "Import",
                    y: yImport
                },
                {
                    type: "line",
                    name: "Export",
                    y: yExport
                },
            ]
        }

        importExportChart = {
            x: xImpExp,
            charts: [
                {
                    type: "line",
                    y: importArray,
                    name: "Import Prices"
                },
                {
                    type: "line",
                    y: exportArray,
                    name: "Export Prices"
                },
            ]
        }

        setGraphLoading(false);
    }

    useEffect(() => {
        let topComponentData = {}
        const getdata = async () => {
            let deviceInfo = await getInverter(params.id, state.token);
            let inverterflow = await getInverterFlow(
                params.id,
                state.token
            );

            const inverterSocDetails = getSocDetails(inverterflow)        // get soc
            topComponentData = {
                ...deviceInfo,
                ...inverterSocDetails,
            };

            topComponentData = {
                // ...topComponentData,
                deviceId: topComponentData.inverterUUID,
                lastActivityTime: moment.utc().format("DD MMM yyyy HH:mm"),
                status: String(topComponentData.desireMode).toUpperCase(),
                soC: topComponentData.soC && Math.round(topComponentData.soC),
                efficiency: topComponentData.efficiencyPercentage,
                storedEnergy: 0,
            };
        };
        if (state.token) {
            getdata().then(() => {
                // console.log("topComponentData ==>", topComponentData)
                setTopData(topComponentData);
            });
            getfromTo(from, to, format, titleAddOn, chunks, timeline);
            initGraphs(from, to, state.token);
        }
    }, [state.token]);

    useEffect(() => {
        if (show) {
            setContainerClass(null);
            setDrawerContainerClass(null);
        } else {
            setContainerClass(classes.containeropen);
            setDrawerContainerClass(classes.drawercontaineropen);
        }
    }, [show]);

    const soc = topData.soC ? topData.soC : 0
    const efficiency = topData.efficiency ? parseFloat(topData.efficiency) * 100 : 0

    const today = moment().format("yyyy-MM-DD")

    const getSocCsv = () => {
        let name = params.id + "-optimization" + "-" + today
        convertJsonToCsv(batteryFlowData, name)
    }

    const getImportPricesCsv = () => {
        let name = "Export-Prices" + "-" + today
        convertJsonToCsv(importGraphData, name)
    }
    const getExportPricesCsv = () => {
        let name = "Import-Prices" + "-" + today
        convertJsonToCsv(exportGraphData, name)
    }

    const SocIcon = () => (
        <Button
            variant={'outlined'}
            size="small"
            disabled={graphLoading}
            onClick={getSocCsv}
        >
            <Download
                size={14}
            />
        </Button>
    )

    const PricesIcon = () => (
        <Box className={classes.csvButtons} >
            <Button
                variant={'outlined'}
                disabled={graphLoading}
                size="small"
                startIcon={
                    <Download
                        size={14}
                    />
                }
                onClick={getImportPricesCsv}>
                Import

            </Button>
            <Button
                variant={'outlined'}
                disabled={graphLoading}
                size="small"
                startIcon={
                    <Download
                        size={14}
                    />
                }
                onClick={getExportPricesCsv}>
                Export

            </Button>
        </Box>
    )

    return (
        <>
            <Stack direction="row">
                <Box
                    height="100%"
                    className={clsx(classes.drawercontainer, drawerContainerClass)}
                >
                    <Drawer show={show} location="customers" />
                </Box>

                <Box className={clsx(classes.container, containerClass)}>
                    <BreadcrumbTrail sx={{ my: 2 }} />
                    <Box className={classes.main}>
                        <Box className={classes.rootStack} >
                            <Card className={classes.infoCard} style={{ flex: 1 }}>
                                <GaugeEChart
                                    color={['#42c991', '#e5e5e5']}
                                    data={soc}
                                />
                                {/* <Typography className={classes.charge}>{soc}%</Typography> */}
                                <Typography
                                    className={classes.sub}
                                    mt={-4}
                                    variant='h6'
                                    color="#d1d4d7"
                                >
                                    State of Charge
                                </Typography>
                            </Card>
                            <Card className={classes.infoCard} style={{ flex: 1 }}>
                                <GaugeEChart2
                                    value={efficiency}
                                />
                                {/* <Typography className={classes.charge}>{soc}%</Typography> */}
                                <Typography
                                    className={classes.sub}
                                    mt={-1}
                                    variant='h6'
                                    color="#d1d4d7"
                                >
                                    Battery Efficiency
                                </Typography>
                            </Card>
                        </Box>
                        <Box className={classes.rootStack} style={{ margin: "15px 0px" }}>
                            {/* <Divider variant="vertical" /> */}
                            {Object.keys(topData).map((key, index) => {
                                let color = "#000"
                                let dataItem = data1.find(d => d.accessor === key)
                                if (dataItem?.color) {
                                    color = dataItem.color(topData[key])
                                }
                                return (
                                    <>
                                        {dataAddOn1[key] && <Card className={classes.infoCard} style={{ height: 200 }}>
                                            <Box className={classes.icon} >
                                                {dataAddOn1[key].icon}
                                            </Box>
                                            <Box className={classes.data}>
                                                <Typography
                                                    className={classes.title}
                                                    style={{ color: color }}
                                                    variant='h6'
                                                >
                                                    {topData[key]}
                                                </Typography>
                                                <Typography className={classes.sub} fontSize="md" fontWeight="600" color="#d1d4d7">
                                                    {dataItem.title}
                                                </Typography>
                                            </Box>
                                        </Card>
                                        }
                                    </>
                                )
                            }
                            )}
                            {/* </Box> */}
                        </Box>
                        <Timeline
                            defaultSetRange={'Today'}
                            data={buttonData}
                            getfromTo={getfromTo}
                        />

                        <Container title={`Battery State of Charge [%] ${titleAddOn}`} sideComponent={<SocIcon />} >
                            <CustomEChart
                                data={socChart}
                                loading={graphLoading}
                                timeline={timeline}
                                max={100}
                            />
                        </Container>

                        <Container title={`Import-Export Prices ${titleAddOn}`} sideComponent={<PricesIcon />} >
                            <CustomEChart
                                loading={graphLoading}
                                timeline={timeline}
                                data={importExportChart}
                            />
                        </Container>

                        <Container title={`Daily Status ${titleAddOn}`} >
                            <CustomEChart
                                data={dailyStatusChart}
                                loading={graphLoading}
                                timeline={timeline}
                            />
                        </Container>

                        {/* <Container
                        style={{ height: "60vh", padding: "2vh" }}
                        title="Battery map"
                    >
                        <GoogleMapReact
                            defaultZoom={10}
                            defaultCenter={{ lat: 34.0522, lng: -118.2437 }}
                            style={{ height: "42vh" }}
                        />
                    </Container> */}
                    </Box>
                </Box>
            </Stack>
        </>
    )
}

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: 60,
        width: "80%",
        transition: "width 0.3s ease-out",
        padding: 0
    },
    main: {
        display: "flex",
        flexDirection: "column",
        gap: 30,
        margin: 40,
        marginTop: 0
    },
    rootStack: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        marginTop: 0,
        marginBottom: -10,
        width: "100%",
        [theme.breakpoints.down("lg")]: {
            padding: "20px 0px",
            marginBottom: -5
        },
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            padding: "20px 10px",
            marginBottom: -5
        },
        [theme.breakpoints.down("sm")]: {
            marginBottom: 0,
            padding: "2px"
        }
    },
    containeropen: {
        width: "100%",
    },
    drawercontainer: {
        width: "20%",
        transition: "width 0.1s ease-out",
    },
    drawercontaineropen: {
        width: "0%",
        zIndex: "100",
    },
    topInfo: {
        width: "100%",
        paddingRight: 36,
        paddingLeft: 36,
        // marginLeft: 40,
        display: "flex",
        flex: 1,
        flexDirection: "row"
    },
    infoCard: {
        padding: 15,
        borderRadius: 0,
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        flex: 1,
        textAlign: "center",
    },
    secTitle: {
        fontSize: 15,
        fontWeight: "bold"
    },
    icon: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end"
    },
    data: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        textAlign: "center"
    },
    title: {
        // fontSize: 16,
    },
    sub: {
        // fontSize: 16
    },
    csvButtons: {
        display: "flex",
        flexDirection: "row",
        gap: 5
    },
    tooltip: {
        color: "#fff",
        backgroundColor: "rgba(0, 0, 0, 0.85)",
        padding: 5,
        textAlign: "center",
        borderRadius: 8,
        boxShadow: "0.3em 0.3em 1em rgb(0 0 0 / 60%)"
    },
    ttFont: {
        fontSize: 12,
        fontWeight: "bold"
    },
    charge: {
        position: "absolute",
        top: "50%",
        left: "50%",
        fontWeight: "bold",
        fontSize: 25,
        transform: "translate(-50%, -10%)",
    }
}));
