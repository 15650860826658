import React, { useContext } from "react";
import { Context as AppContext } from "../context/AppContext";
import { Box, Divider, IconButton } from "@mui/material";
import { AiOutlineMenu } from "react-icons/ai";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";

function Header() {
  const { state, setShow, show } = useContext(AppContext);
  const classes = useStyles()
  let currentUser = JSON.parse(sessionStorage.getItem("currentUser"));
  return currentUser ? (
    <Box
      className={classes.root}
    >
      <Box
        className={classes.container}
      >
        <Box
          className={classes.logo}
        >
          <img width="100px" src="https://chargesyncadminwebsite.azurewebsites.net/logo_inline_black.475539816c6af0d56b4f.jpg" ></img>
        </Box>
        <IconButton
          sx={{ m: 2 }}
          onClick={() => {
            setShow(!show);
          }}
        >
          <AiOutlineMenu fontSize="20" color="#d1d4d7" />
        </IconButton>
      </Box>
    </Box>
  ) : null;
}

export default Header;

export const CSHeader = () => {
  const classes = useStyles()
  return (
    <Box style={{ display: "flex" }} className={classes.header}>
      <Box className={classes.logoBox}>
        <Link to="/">
          <img src="https://chargesyncadminwebsite.azurewebsites.net/logo_inline_black.475539816c6af0d56b4f.jpg" width={130} />
        </Link>
      </Box>
    </Box >
  )
}


const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "#fff",
    height: 60,
    borderWidth: 1,
    borderColor: "#e4e5e6",
    zIndex: 200,
  },
  container: {
    width: "20%",
    height: "100%",
    borderRight: "1px solid #e4e5e6 !important",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    [theme.breakpoints.down('md')]: {
      width: "100%"
    }
  },
  logo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "90%",
    height: "100%",
  },
  header: {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    backgroundColor: "#fff",
    width: "100%",
    top: 0,
    left: 0
  },
  logoBox: {
    width: "20%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRight: "1px solid #d1d4d7",
    height: 60,
    [theme.breakpoints.down('md')]: {
      width: "100%",
      borderRight: "none"
    }
  }
}))