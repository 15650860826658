export const getSocDetails = (inverterflow) => {
    let soc = 0
    if (Array.isArray(inverterflow) && inverterflow.length > 0) {
        let arr = AscDateArray(inverterflow, "periodFrom").reverse()
        soc = parseFloat(inverterflow[0].soC)
        if (soc === 0) soc = parseFloat(inverterflow[1].soC)
    }
    return {
        soC: soc
    }
}

export const AscDateArray = (array, key) => {
    let dataArray = array
    const compareDates = (a, b) => new Date(a[key]) - new Date(b[key]);

    dataArray.sort(compareDates);
    return dataArray
}

export const convertJsonToCsv = (jsonArray, name) => {
    const headers = Object.keys(jsonArray[0]);

    const csvContent = [
        headers.join(','),
        ...jsonArray.map(obj => headers.map(key => obj[key]).join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });

    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = `${name}.csv`;

    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
}

export const getChunkedData = (data) => {
    let chunked = data
    if (Array.isArray(data) && data.length) {
        console.log("data.length ==>", data.length)
    }
    return chunked
}