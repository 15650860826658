import React, { useContext, useEffect, useState } from "react";
import { Context as AppContext } from "../context/AppContext";

import { Box, Stack } from "@mui/material";
import {
  buttonData,
  data1,
  data2,
  dataAddOn1,
  dataAddOn2,
} from "../data/batteryData";
import Timeline from "../components/timeline";
import Card from "../components/card";
import Container from "../components/container";
import Drawer from "../components/drawer";

import GoogleMapReact from "google-map-react";
import { StyleSheet } from "react-native-web";
import {
  getDevice,
  getLatestDeviceChargeState,
  getDeviceCarbonFlowsSum,
  getDeviceCashFlowsSum,
  getDeviceChargeStates,
  getDeviceCashFlows,
  getDeviceOptimisationPrices,
  GetDevicePlannedImports,
  getDeviceEnergyFlows,
  getDeviceCarbonFlows,
} from "../Api/homeApi";
import moment from "moment";
import { useParams } from "react-router-dom";
import CustomEChart from "../components/Echarts";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import BreadcrumbTrail from "../components/BreadcrumbsTrail";

let chartLabels = [];

let marketPriceandCashSavingsChart = {
  x: chartLabels,
  charts: [],
};

let accumulatedImbalanceChart = {
  x: chartLabels,
  charts: [],
};

let stateOfChargeChart = {
  x: chartLabels,
  charts: [],
};

let carbonSavingsChart = {
  x: chartLabels,
  charts: [],
};

let topComponentData = {};

let carbonSaved = 0.0;
let moneyMade = 0.0;
let soC = 0.0;
let systemEfficiency = 0.0;

let bottomComponentData = {};

function Battery() {
  const classes = useStyles()
  const [containerClass, setContainerClass] = useState("");
  const [drawerContainerClass, setDrawerContainerClass] = useState("");
  const [portfolioTopData, setPortfolioTopData] = useState({});
  const [portfolioBottomData, setPortfolioDataBottomData] = useState({});
  const [graphLoading, setGraphLoading] = useState(false)
  const [from, setFrom] = useState(moment().utc().startOf("day"));
  const [to, setTo] = useState(moment().utc());
  const [chunks, setChunks] = useState(1);
  const [titleAddOn, setTitleAddon] = useState("- Today");
  const [format, setFormat] = useState("HH");
  const [timeline, setTimeline] = useState("Today")

  const { state, show } = useContext(AppContext);

  const params = useParams();

  //#region Functions
  useEffect(() => {
    if (show) {
      setContainerClass(null);
      setDrawerContainerClass(null);
    } else {
      setContainerClass(classes.containeropen);
      setDrawerContainerClass(classes.drawercontaineropen);
    }
  }, [show]);

  useEffect(() => {
    const getdata = async () => {
      let deviceInfo = await getDevice(params.id, state.token);
      let deviceStoredEnergy = await getLatestDeviceChargeState(
        params.id,
        state.token
      );
      topComponentData = {
        ...deviceInfo,
        ...deviceStoredEnergy,
      };

      topComponentData = {
        ...topComponentData,
        lastActivityTime: moment.utc().format("DD MMM yyyy HH:mm"),
        status: topComponentData.status === 1 ? "Offline" : "Operational",
        soC: topComponentData.soC && Math.round(topComponentData.soC * 100),
        storedEnergy:
          topComponentData.storedEnergy &&
          Math.round(topComponentData.storedEnergy * 100) / 100,
        capacity:
          topComponentData.capacity &&
          Math.round(topComponentData.capacity * 100) / 100,
      };
    };
    if (state.token) {
      getdata().then(() => {
        setPortfolioTopData(topComponentData);
      });
      getfromTo(from, to, format, titleAddOn, chunks, timeline);
      initGraphs(from, to, chunks, state.token);
    }
  }, [state.token]);

  const getfromTo = async (from, to, format, titleAddon, chunks, tag) => {
    setFrom(from);
    setTo(to);
    setChunks(chunks);
    setTitleAddon(titleAddon);
    setFormat(format);
    setTimeline(tag)
    moneyMade = await getDeviceCashFlowsSum(params.id, from, to, state.token);
    carbonSaved = await getDeviceCarbonFlowsSum(
      params.id,
      from,
      to,
      state.token
    );
    soC = await getDeviceChargeStates(params.id, from, to, chunks, state.token);

    await initGraphs(from, to, chunks, state.token, chartLabels);

    bottomComponentData = {
      moneyMade: moneyMade && Math.round(moneyMade * 100) / 100,
      carbonSaved: carbonSaved && Math.round(carbonSaved * 100) / 100,
      systemEfficiency: systemEfficiency && Math.round(systemEfficiency * 100),
      soC: soC?.soC && Math.round(soC?.soC * 100),
    };

    setPortfolioDataBottomData(bottomComponentData);
  };

  const initGraphs = async (from, to, chunks, token) => {
    setGraphLoading(true);
    let cashSavingsGraphData = await getDeviceCashFlows(
      params.id,
      from,
      to,
      chunks,
      token
    );
    let marketPricesGraphData = await getDeviceOptimisationPrices(
      params.id,
      from,
      to,
      chunks,
      token
    );
    let plannedImportsChartData = await GetDevicePlannedImports(
      params.id,
      from,
      to,
      chunks,
      token
    );
    let netImportChartData = await getDeviceEnergyFlows(
      params.id,
      from,
      to,
      chunks,
      token
    );

    let stateOfChargeData = await getDeviceChargeStates(
      params.id,
      from,
      to,
      chunks,
      token
    );
    let carbonSavingsChartData = await getDeviceCarbonFlows(
      params.id,
      from,
      to,
      chunks,
      token
    );

    let cashSavingsGraphDataArray = cashSavingsGraphData?.map(
      (item) => item.cashFlow
    );

    let marketPricesGraphDataArray = marketPricesGraphData?.map(
      (item) => item.price
    );
    let plannedImportsChartDataArray = plannedImportsChartData?.map(
      (item) => item.netImport
    );
    let netImportChartDataArray = netImportChartData?.map(
      (item) => item.netImport
    );
    let accumulatedDifferenceArray = plannedImportsChartDataArray?.map(
      (item, index) => item - netImportChartDataArray[index]
    );
    let stateOfChargeArray = stateOfChargeData?.map((item) => item.soC);
    let carbonSavingsChartDataArray = carbonSavingsChartData?.map(
      (item) => item.carbonFlow
    );

    chartLabels = marketPricesGraphData?.map((item) => item.periodFrom)

    marketPriceandCashSavingsChart = {
      x: chartLabels,
      charts: [
        {
          type: "line",
          name: "Cash Savings",
          y: marketPricesGraphDataArray
        },
        {
          type: "bar",
          name: "Market Price",
          y: cashSavingsGraphDataArray
        },
      ],
    };

    accumulatedImbalanceChart = {
      x: chartLabels,
      charts: [
        {
          type: "line",
          name: "Accumulated Difference",
          y: accumulatedDifferenceArray
        },
        {
          type: "bar",
          name: "Net Import",
          y: netImportChartDataArray
        },
        {
          type: "bar",
          name: "Planned Imports",
          y: plannedImportsChartDataArray
        },
      ],
    };

    stateOfChargeChart = {
      x: chartLabels,
      charts: [
        {
          type: "line",
          name: "State of Charge",
          y: stateOfChargeArray
        },
      ],
    };

    carbonSavingsChart = {
      x: chartLabels,
      charts: [
        {
          type: "bar",
          name: "Carbon Savings",
          y: carbonSavingsChartDataArray
        },
      ],
    };

    setGraphLoading(false);
  };

  //#endregion

  return (
    <>
      <Stack direction="row">
        <Box
          height="100%"
          className={clsx(classes.drawercontainer, drawerContainerClass)}
        >
          <Drawer show={show} location="batteries" />
        </Box>

        <Box className={clsx(classes.container, containerClass)}>
          <BreadcrumbTrail sx={{ my: 2 }} />
          <Box className={classes.main}>
            <Card
              labels={data1}
              dataAddOn={dataAddOn1}
              portfolioData={portfolioTopData}
            />
            <Timeline defaultSetRange={'Today'} data={buttonData} getfromTo={getfromTo} />
            <Card
              labels={data2}
              dataAddOn={dataAddOn2}
              portfolioData={portfolioBottomData}
            />

            <Container title={`Market Price and Cash savings [p] ${titleAddOn}`}>
              <CustomEChart
                timeline={timeline}
                loading={graphLoading}
                data={marketPriceandCashSavingsChart}
              />
            </Container>

            <Container title={`Accumulated Imbalance ${titleAddOn}`}>
              <CustomEChart
                timeline={timeline}
                loading={graphLoading}
                data={accumulatedImbalanceChart}
              />
            </Container>

            <Container title={`State of Charge ${titleAddOn}`}>
              <CustomEChart
                timeline={timeline}
                loading={graphLoading}
                data={stateOfChargeChart}
              />
            </Container>

            <Container title={`Carbon savings [g CO2] ${titleAddOn}`}>
              <CustomEChart
                timeline={timeline}
                loading={graphLoading}
                data={carbonSavingsChart}
              />
            </Container>

            {/* <Container
              title="Battery map"
            >
              <Box style={{ height: "42vh", position: "relative" }} >

                <GoogleMapReact
                  defaultZoom={10}
                  defaultCenter={{ lat: 34.0522, lng: -118.2437 }}
                  style={{ height: "42vh" }}
                />
              </Box>
            </Container> */}
          </Box>
        </Box>
      </Stack>
    </>
  );
}

export default Battery;

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: 60,
    width: "80%",
    transition: "width 0.3s ease-out",
    padding: 0
  },
  main: {
    display: "flex",
    flexDirection: "column",
    gap: 30,
    margin: 40,
    marginTop: 0
  },
  containeropen: {
    width: "100%",
  },
  drawercontainer: {
    width: "20%",
    transition: "width 0.1s ease-out",
  },
  drawercontaineropen: {
    width: "0%",
    zIndex: "100",
  },
}));
