import React from "react";
import { Box, Card, LinearProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import theme from "../theme";

function Container(props) {
  const multipleChild = React.Children.count(props.children) > 1
  const classes = useStyles()

  return (
    <Card className={classes.root} style={props?.style?.root}>
      <Box
        style={{ backgroundColor: props.focused ? theme.palette.primary.main : "#F8F9FA", ...props?.style?.headingContainer }}
        className={classes.headingContainer}
      >
        <Box
          px={3}
          py={1}
          className={classes.heading}
          style={{ backgroundColor: props.focused ? theme.palette.primary.main : "#F8F9FA", ...props?.style?.heading }}
        >
          <Typography style={{ fontWeight: "bold", color: props.focused ? "#fff" : theme.palette.text.primary }}>{props.title}</Typography>
          {props.icon && <props.icon />}
          {props.sideComponent}
        </Box>
        {props.loading && <LinearProgress />}
        <Box

          py={2}
          px={1}
          style={{ flexDirection: multipleChild ? "row" : "column", ...props?.style?.child }}
          className={classes.childContainer}
        >
          {props.children}
        </Box>
      </Box>
    </Card>
  );
}

export default Container;

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 5,
    marginBottom: 5,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down('md')]: {
      padding: 0
    }
  },
  headingContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    borderColor: "rgb(248, 249, 250)",
    borderWidth: 1,
    backgroundColor: "#F8F9FA",
    paddingTop: 10
  },
  heading: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#f8f9fa"
  },
  childContainer: {
    borderTop: "1px solid #E5E7EB",
    display: "flex",
    width: "100%",
    backgroundColor: "#fff",
    [theme.breakpoints.down('md')]: {
      padding: 0
    }
  }
}))
